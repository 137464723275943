import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    MenuItem,
    Paper,
    RadioGroup,
    Select,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notifyError, notifySuccess, UserContext } from '../App';
import { analytics, functions } from '../firebase/firebase';
import { Stack } from '@mui/system';
import { httpsCallable } from 'firebase/functions';
import { logEvent } from 'firebase/analytics';
import { useHistory } from 'react-router-dom';
import { createCheckoutSession } from '../firebase/firebaseCalls';
// ...

export function PaymentForm() {
    // const stripe = useStripe();
    // const elements = useElements();
    const [selectedAmount, setSelectedAmount] = useState('package1');


    const { user } = useContext(UserContext);
    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Payment',
            page_location: window.location.href,
            page_path: '/payment',
        });
    }, []);

    if (!user.uid) {
        notifyError('You must be logged in to pay');
        return <></>;
    }

    const PACKAGE_BY_AMOUNT = {
        100: 'package1',
        500: 'package2'
    };

    const handleChange = (event) => {
        setSelectedAmount(event.target.value);
    };




    const handleSubmit = async (event) => {
        event.preventDefault();

        logEvent(analytics, 'payment_started', {
            value: selectedAmount,
            currency: 'EUR',
            package: selectedAmount
        });

        const stripeUrl = (await createCheckoutSession(selectedAmount, user.uid)).url;

        document.location.href = stripeUrl;

    };

    return (
        // Center payment form
        <Paper
            component="form"
            onSubmit={handleSubmit}
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            < Stack spacing={2} direction="column" sx={{ width: '100%' }}>

                <FormControl sx={{ m: 1, width: '100%' }}>
                    <FormLabel>Select Credit Package</FormLabel>
                    <Select value={selectedAmount} onChange={handleChange} fullWidth>
                        <MenuItem value={'package1'}>25 GPT-4 Credits for 1€</MenuItem>
                        <MenuItem value={'package2'}>250 GPT-4 Credits for 5€ (save 50%)</MenuItem>
                    </Select>
                </FormControl>
                {/* <CardElement
                    sx={{
                        p: 2,
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}

                /> */}
                <Typography variant="body2" color="text.secondary" align="center" fontFamily={'Roboto'}>
                    Credits are deduced from your account when you use the GPT-4 model according to the size you choose
                </Typography>
                <strong></strong>
                <Button variant="contained" color="primary"
                    type="submit">
                    Pay
                </Button>
                {/* <PaymentElement /> */}
            </Stack>
        </Paper>
    );

}

