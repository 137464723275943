import React from 'react';
import { Paper } from '@mui/material';

const TermsOfService = () => {
    return (
        <div>
            <h1>Terms of Service for poetgpt.koll.ai</h1>
            <p>Last updated: April 1, 2023</p>
            <p>
                Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the poetgpt.koll.ai website ("Service", "Website") operated by Koll AI ("us", "we", or "our").
            </p>
            <p>
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
            </p>
            <h2>1. Access and Usage</h2>
            <ol type="a">
                <li>
                    Anonymous users are permitted to access and use the gpt3.5-turbo model on the Website for free, subject to a daily limit that may change depending on the traffic.
                </li>
                <li>
                    To access and use the gpt4 model, users are required to purchase credits. The amount of credits used is determined by our backend server and may change at our discretion.
                </li>
                <li>
                    We reserve the right to limit, suspend, or terminate your access to the Website and its services at any time without prior notice, for any reason, including but not limited to violation of these Terms.
                </li>
            </ol>
            <h2>2. Intellectual Property Rights</h2>
            <p>
                Until further notice, any content generated by this Website is the intellectual property of poetgpt.koll.ai. Unauthorized use, reproduction, or distribution of such content is strictly prohibited.
            </p>
            <h2>3. Dispute Resolution</h2>
            <p>
                In the event of any dispute or disagreement between you and Koll AI, users should contact philippe.henri.saade@gmail.com to initiate the dispute resolution process. We will make every effort to resolve the dispute in a fair and timely manner.
            </p>
            <h2>4. GDPR Compliance</h2>
            <p>
                We are committed to protecting your privacy and ensuring compliance with the General Data Protection Regulation (GDPR). By using this Website, you agree to the collection and use of your personal data in accordance with our Privacy Policy.
            </p>
            <h2>5. Governing Law and Jurisdiction</h2>
            <p>
                These Terms shall be governed and construed in accordance with the laws of France, without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located in France to resolve any legal matter arising from these Terms.
            </p>
            <h2>6. Changes to the Terms of Service</h2>
            <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
            </p>
            <p>
                Contact Us
            </p>
            <p>
                If you have any questions about these Terms, please contact us at philippe.henri.saade@gmail.com.
            </p>
        </div>
    );
};

export const TermsOfServicePage = () => {
    return (
        <div>
            <Paper elevation={3} sx={{ padding: 4, margin: 4 }}>
                <TermsOfService />
            </Paper>
        </div>
    );
}