import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, Card, Grid, IconButton, Typography, CardContent, Button, CircularProgress } from "@mui/material";
import { red } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { notifyError, UserContext, UserDataContext } from "../App";
import { votePoem } from "../firebase/firebaseCalls";
import './justify.css'

export const PoemItem = ({ key, poem, displayVotes, displayMode, loading }) => {
    const { user } = useContext(UserContext);
    const { userData, setUserData } = useContext(UserDataContext);
    // const votes = userData.votes || {};
    const setVotes = (newVotes) => setUserData({ ...userData, votes: newVotes });

    const [upvoteCount, setUpvoteCount] = useState(poem.upvotes);
    const [downvoteCount, setDownvoteCount] = useState(poem.downvotes);

    // const [userVote, setUserVote] = useState(
    //     !displayVotes ? null : poem.id in votes ? votes[poem.id] : null
    // );


    useEffect(() => {
        console.log('poem', poem)
    }, [])

    const renderAcrosticLine = (poemText) => {
        // strong on the first letter of the line
        return poemText.split('\n').map((line, index) => (
            <div key={index}>
                <b>{line.slice(0, 1)}</b>
                {line.slice(1)}
            </div>
        ));
        // return poemText
    };

    const renderAcrosticWord = (poemText) => {
        // strong on the first word of each line
        return poemText.split('\n').map((line, index) => (
            <div key={index}>
                <b>{line.split(' ')[0]}</b>
                {line.slice(line.split(' ')[0].length)}
            </div>
        ));
    };


    // const handleVote = async (poemId, voteType) => {
    //     if (!user) {
    //         notifyError('You must be logged in to vote');
    //     }

    //     if (user) {
    //         if (voteType === 'upvote') {
    //             setUpvoteCount(upvoteCount + 1);
    //         }
    //         else if (voteType === 'downvote') {
    //             setDownvoteCount(downvoteCount + 1);
    //         }

    //         setVotes((votes) => {
    //             return {
    //                 ...votes,
    //                 [poemId]: voteType,
    //             };
    //         });
    //         setUserVote(voteType);

    //         await votePoem(poemId, voteType);
    //         console.log('voted', voteType, 'for', poemId)
    //     }


    // };


    const copyContentToClipboard = () => {
        let content = '';
        if (poem.critics.length > 0) {
            content = `
Title: ${poem.title}
Poem:
${poem.poem}
\tAuthor: PoetGPT, https://poetgpt.koll.ai
Critics: ${poem.critics}
`;
        } else {
            content = `
Title: ${poem.title}
Poem:
${poem.poem}
\tAuthor: PoetGPT, https://poetgpt.koll.ai
`;
        }


        navigator.clipboard.writeText(content).then(
            () => {
                console.log("Content copied to clipboard");
            },
            (err) => {
                console.error("Error copying content to clipboard", err);
            }
        );
    };

    return (


        <Card key={key}
            elevation={10}
            sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: 1,
                border: '1px solid #e0e0e0',
                margin: 'auto',
                boxShadow: '0 2px 4px 1px gray',

            }}
        >
            <CardContent>
                {/* letter spacing */}
                <Typography variant="h6" gutterBottom fontFamily={'Roboto Mono'} letterSpacing={1}>
                    <strong>{poem.title}</strong>
                </Typography>

                {loading && (
                    <Box display="flex" justifyContent="center" marginTop={2}>
                        <CircularProgress />
                    </Box>

                )}


                <div>
                    <Typography variant="body1" fontFamily={'Roboto Mono'} fontSize={18}>
                        <div style={{ whiteSpace: "pre-wrap", textAlign: 'justify', textJustify: 'inter-word' }}>
                            <blockquote>
                                {
                                    displayMode === 'acrosticWord' ? renderAcrosticWord(poem.poem)
                                        : displayMode === 'acrosticLine' ? renderAcrosticLine(poem.poem)
                                            : poem.poem
                                }
                            </blockquote>
                            <figcaption>— PoetGPT, <cite>https://poetgpt.koll.ai</cite> </figcaption>
                        </div>
                    </Typography>
                    <Box display="flex" justifyContent="center" marginTop={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={copyContentToClipboard}
                        >
                            Copy to Clipboard
                        </Button>
                    </Box>
                </div>

                {/* {displayVotes && (
                    <Box display="flex" justifyContent="space-between" marginTop={2}>
                        <IconButton onClick={() => handleVote(poem.id, 'upvote')} disabled={userVote === 'upvote'} sx={userVote === 'upvote' ? { color: 'success' } : {}}>
                            <ThumbUp />
                        </IconButton>

                        <Box sx={{ margin: 1 }}> {upvoteCount}  </Box>
                        <Box sx={{ margin: 1 }}> - </Box>
                        <Box sx={{ margin: 1 }}> {downvoteCount}  </Box>

                        <IconButton onClick={() => handleVote(poem.id, 'downvote')} disabled={userVote === 'downvote'} sx={userVote === 'downvote' ? { color: red[500] } : {}}>
                            <ThumbDown />
                        </IconButton>
                    </Box>
                )} */}

                {poem.critics !== "" && (
                    <Box display="flex" justifyContent="space-between" marginTop={2}>
                        <Typography variant="caption">
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                {poem.critics}
                            </div>
                        </Typography>
                    </Box>
                )}

            </CardContent>
        </Card>
    );
};
