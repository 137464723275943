import React, { useState } from 'react';
import { Box, Button, Container, Tab, Tabs, Typography } from '@mui/material';
import { UserContext } from '../App';
import { useContext } from 'react';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { PaymentForm } from './PaymentForm';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function Profile() {
    // if ?tab=purchase is in the URL, defaultTab will be 2
    const [value, setValue] = useState(new URLSearchParams(window.location.search).get('tab') === 'purchase' ? 2 : 0);
    const { user } = useContext(UserContext);
    console.log(value)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (!user) return (<div>Loading...</div>);

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                {/* Profile for {user.email} */}
            </Typography>
            <Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Profile tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Profile Management" />
                    <Tab label="Payment History" />
                    <Tab label="Buy Credits" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Profile Management
                </Typography>
                {/* Add profile management components like reset password, change email, etc. */}
                <Button variant="contained" color="primary">
                    Reset Password
                </Button>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Payment History
                </Typography>
                {/* Add a table or list to display the user's payment history */}
            </TabPanel>
            <TabPanel value={value} index={2}>

                {/* Add a form to select the credit package and initiate the payment process */}
                {/* Border around div */}
                {/* <Elements stripe={stripePromise}
                    options={{
                        fonts: [
                            {
                                cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
                            },
                        ],
                    }}

                    style={{
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    }}
                > */}
                <PaymentForm />
                {/* </Elements> */}
            </TabPanel>
        </Container >
    );
}

export default Profile;
