import React from 'react';
import { logEvent } from 'firebase/analytics';
import { useContext, useEffect } from 'react';
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';
import { Router, useHistory } from 'react-router-dom';
import { AuthModalContext, UserContext } from '../App';
import { analytics } from '../firebase/firebase';

import './PricingPage.css';
import { Typography } from '@mui/material';
export default function PricingPage() {

    const { authModalOpen, setAuthModalOpen } = useContext(AuthModalContext)
    const { user, setUser } = useContext(UserContext);
    let history = useHistory();


    useEffect(() => {


        logEvent(analytics, 'page_view', {
            page_title: 'Pricing',
            page_location: window.location.href,
            page_path: '/pricing',
        });
    }, []);


    const redirectRegistered = () => {
        // Link to home page using react router link


        if (user) {
            history.push('/');
        }
        else {
            setAuthModalOpen(true);
        }
    }

    const redirectAnonymous = () => {
        // window.location.href = '/';
        history.push('/');

    }

    const redirectPayment = () => {
        if (user) {
            history.push('/profile?tab=purchase');
        }
        else {
            setAuthModalOpen(true);
        }
    }


    return (
        <div>
            <PricingTable>
                <PricingSlot onClick={redirectAnonymous} buttonText='TRY IT FREE' title='Anonymous Usage' priceText='FREE'>
                    <PricingDetail> <b>ChatGPT</b> model </PricingDetail>
                    <PricingDetail> <b>Short</b> output</PricingDetail>
                    <PricingDetail strikethrough> <b>GPT-4</b> model</PricingDetail>
                </PricingSlot>
                <PricingSlot onClick={redirectRegistered} buttonText='SIGN UP' title='Registered Usage' priceText='FREE'>
                    <PricingDetail> <b>GPT-4</b> model: <b>25 free credits!</b></PricingDetail>
                    <PricingDetail> <b>Unlimited ChatGPT</b> model </PricingDetail>
                    <PricingDetail> <b>Short, medium or large</b> output</PricingDetail>
                </PricingSlot>
                <PricingSlot highlighted onClick={redirectPayment} buttonText='Go To Store' title='25 GPT-4 Credits' priceText='1€'>
                    <PricingDetail> <b>GPT-4</b> model</PricingDetail>
                    <PricingDetail> pay as you go</PricingDetail>
                    <PricingDetail> <b>Unlimited</b> prompt characters</PricingDetail>
                </PricingSlot>
                <PricingSlot highlighted onClick={redirectPayment} buttonText='Go To Store' title='250 GPT-4 credits' priceText='5€ (save 50%)'>
                    <PricingDetail> <b>GPT-4</b> model</PricingDetail>
                    <PricingDetail> pay as you go</PricingDetail>
                    <PricingDetail> <b>Unlimited</b> prompt & generated lines</PricingDetail>
                </PricingSlot>
            </PricingTable>
            <Typography variant="body2" color="text.secondary" align="center">
                <strong> One GPT-4 credit is equivalent to 1 generated paragraph </strong>
            </Typography>
        </div>
    )
}