import { useContext, useEffect } from "react";
import { notifyError, notifySuccess, UserContext } from "../App";

export const CheckoutPage = () => {
    // if success param in url is true, show success message
    const success = new URLSearchParams(window.location.search).get('success');

    const { user } = useContext(UserContext);

    useEffect(() => {
        if (success === 'false') {
            notifyError('Payment failed. Please try again.');
            return
        }
        if (success === 'true') {
            notifySuccess('Payment successful. Thank you for your support!');
        }

        // call firebase function to check user payment history


    }, [success]);

    return (
        <div className="checkout-page">
            {
                success === 'true' && (
                    <div className="success-message">
                        <h1>Thank you for your support!</h1>
                        <p>Your payment was successful. You can now use your credits to generate poems.</p>
                    </div>

                )

            }

            {
                success === 'false' && (
                    <div className="error-message">
                        <h1>Payment failed</h1>
                        <p>Something went wrong. Please try again.</p>
                    </div>

                )
            }
        </div>
    )
}


