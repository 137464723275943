import { useContext, useState, useRef, useEffect } from 'react';
import { Box, FormControl, Card, Paper, CardContent, IconButton, Container, Grid, Select, MenuItem, TextField, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { votePoem } from '../firebase/firebaseCalls';
import { notifyError, UserContext } from '../App';
import { PoemItem } from '../components/PoemItem';
import { PanelGenParams } from '../components/PanelGenParams';
import { savePoem, rewritePoemWithCritics } from '../firebase/firebaseCalls';
import { toast } from 'react-toastify';
import { ScrollDownFab } from '../components/ScrollDownFab';
import { analytics } from '../firebase/firebase';
import { logEvent } from 'firebase/analytics';





const HomePage = () => {
    const { user, setUser } = useContext(UserContext)
    const [poemHistory, setPoemHistory] = useState([]);

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingRewrite, setLoadingRewrite] = useState(false);

    const [generatedCritics, setGeneratedCritics] = useState('');

    const [isAnimationEnabled, setIsAnimationEnabled] = useState(true);
    const [generatedPoem, setGeneratedPoem] = useState({
        poem: '',
        topic: '',
        id: '',
        acrosticWord: '',
        acrosticLine: '',
        language: navigator.language.slice(0, 2) == 'fr' ? 'qc' : ['en', 'fr', 'es', 'de', 'it', 'pt'].includes(navigator.language.slice(0, 2)) ? navigator.language.slice(0, 2) : 'en',
        poemSize: 'small',
        model: 'chatgpt',
        title: '',
        critics: '',
        generationMode: 'free'

    });

    const [displayMode, setDisplayMode] = useState('free'); // free, acrosticWord, acrosticLine

    //analytics
    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Home',
            page_location: window.location.href,
            page_path: '/',
        });
    }, []);


    useEffect(() => {
        setSaveButtonDisabled(false);
    }, [generatedPoem]);


    const loadPoemFromHistory = (poem) => {
        setGeneratedPoem(poem);
    };


    const handleCritics = async () => {
        setLoadingRewrite(true);
        setIsAnimationEnabled(false);
        if (generatedCritics !== "") {
            console.log('generatedPoem', generatedPoem)
            await rewritePoemWithCritics(generatedPoem.poem, generatedCritics,
                generatedPoem.poemSize,
                generatedPoem.topic,
                generatedPoem.language,
                generatedPoem.acrosticWord,
                generatedPoem.acrosticLine,
                generatedPoem.model)
                .then((newPoem) => {
                    console.log('newPoem', newPoem);
                    const poemObj = { ...generatedPoem, poem: newPoem.poem, critics: newPoem.critics };
                    setGeneratedPoem(poemObj);
                    setPoemHistory((poemHistory) => [poemObj, ...poemHistory]);
                    setLoadingRewrite(false);
                    toast.success('Poem generated successfully');
                })
                .catch((error) => {
                    console.error('Error generating poem:', error.message);
                    toast.error('Error: ' + error.message);
                    setLoadingRewrite(false);
                });


        }
    }

    const handleSavePoem = async () => {
        console.log('called save')
        setSaveButtonDisabled(true);
        if (generatedPoem) {
            const res = await savePoem(generatedPoem)
                .then((res) => {
                    console.log('res', res);
                    toast.success('Poem saved successfully');
                })
                .catch((error) => {
                    console.error('Error saving poem:', error);
                    toast.error('Error saving poem');
                });


        }
    };




    return (
        <Container maxWidth="md" sx={{ marginTop: 2 }}>
            <ScrollDownFab />
            {!user && (
                // center typography
                <Box display="flex" justifyContent="center">
                    <Typography variant="subtitle2" gutterBottom color={'error'} >
                        Generate better poems & acrostic with <strong>25 FREE gpt-4 credits</strong> when you sign up!
                    </Typography>
                </Box>
            )}
            <Box display="flex" justifyContent="center">
                <Typography variant="subtitle2" gutterBottom color={'error'} >
                    {/* <b>New! April 4:</b> New languages: <b>French Picard/Ch'ti</b> and <b>French Canadian</b> (works better with GPT-4 model) */}
                    {/* <b>New! April 7:</b> Updated Acrostic Word generation mode (works better with GPT-4 model) */}
                    <b>New! May 15:</b> <b>Android App</b> beta version available <a href="https://play.google.com/store/apps/details?id=com.poetgptmobile" target="_blank">here !!</a>
                </Typography>
            </Box>

            <PanelGenParams generatedPoem={generatedPoem} setGeneratedPoem={setGeneratedPoem}
                setPoemHistory={setPoemHistory}
                isAnimationEnabled={isAnimationEnabled} setIsAnimationEnabled={setIsAnimationEnabled}
                loading={loading} setLoading={setLoading}
                setDisplayMode={setDisplayMode}
                setLoadingRewrite={setLoadingRewrite}
            />

            <br />
            <br />


            <div id="generatedPoemBlock">
                <Grid Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} md={8} alignItems="center" sx={{ width: '100%' }}>


                        {!loadingRewrite && (
                            <PoemItem id="generatedPoem"
                                poem={generatedPoem}
                                key={'index'}
                                userVote={null}
                                displayVotes={false}
                                loading={loading}
                                displayMode={displayMode}

                            />
                        )}
                        {loadingRewrite && (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        )}

                    </Grid>
                    {/* Center vertically */}

                    <Grid item xs={12} md={4} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>

                        <Paper
                            elevation={10}
                            sx={{
                                padding: 2,
                                borderRadius: 2,
                            }}
                        >
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                label="Critics"
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                value={generatedCritics}
                                                onChange={(e) => setGeneratedCritics(e.target.value)}
                                                helperText="Rewrite the poem with your critics"
                                                sx={{ marginBottom: 2 }}
                                                onFocus={() => setIsAnimationEnabled(false)}
                                                placeholder="Each line should start with the next letter in the sequence ..."

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth disabled={generatedCritics === '' || loadingRewrite}
                                            type='submit' variant="contained" color="primary" onClick={handleCritics}
                                        >Rewrite</Button>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth type='submit' variant="contained" color="primary" onClick={handleSavePoem} disabled={saveButtonDisabled} >Save to Archives</Button>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                    </Grid>

                </Grid>
            </div>


            <Typography variant="h4" component="h2" sx={{ marginTop: 4, marginBottom: 1 }}>
                History {poemHistory.length > 0 && !user && "(Will be erased when you leave the page)"}
            </Typography>
            <Typography>
                Click on an element of the grid to load the corresponding poem back.
                {poemHistory.length === 0 && (
                    <span> You have not generated any poem yet.</span>
                )}

            </Typography>

            {/* 3. Create a grid to display the history of generated poems. */}
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                {poemHistory.map((poem, index) => (
                    <Grid item key={index} xs={12} sm={6} onClick={() => loadPoemFromHistory(poem)}>
                        <PoemItem
                            id={`historyPoem-${index}`}
                            poem={poem}
                            key={index}
                            userVote={null}
                            displayVotes={false}
                            sx={{
                                cursor: 'pointer',
                                border: poem.id === generatedPoem.id ? '2px solid #3f51b5' : 'none',
                            }}
                        />
                    </Grid>
                ))}
            </Grid>


        </Container >
    );
};

export default HomePage;


