import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Tab, Tabs, Box } from '@mui/material';
import { analytics, auth, db } from '../firebase/firebase';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import GoogleButton from 'react-google-button';
import { Checkbox, FormControlLabel, Link } from '@mui/material';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { Typography } from '@mui/material';

const AuthModal = ({ open, onClose }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const [privacyTOSChecked, setPrivacyTOSChecked] = useState(false);
    const [newsletterChecked, setNewsletterChecked] = useState(false);


    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
        setError('');
    };

    const handleSignUp = async () => {
        try {
            const resp = await createUserWithEmailAndPassword(auth, email, password);
            logEvent(analytics, 'sign_up', {
                method: 'email',
            });
            const uid = resp.user.uid;
            const userRef = doc(db, 'Users', uid);
            await updateDoc(userRef, {
                newsletter: newsletterChecked
            });
            onClose();
        } catch (error) {
            setError(error.message);
        }
    };

    const handleSignIn = async () => {
        try {
            const resp = await signInWithEmailAndPassword(auth, email, password);
            const uid = resp.user.uid;
            const userRef = doc(db, 'Users', uid);
            await updateDoc(userRef, {
                newsletter: newsletterChecked
            });


            logEvent(analytics, 'sign_up', {
                method: 'google',
            });
            onClose();
        } catch (error) {
            setError(error.message);
        }
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();

        try {
            const resp = await signInWithPopup(auth, provider)

            // update newsletter subscription in firestore
            const uid = resp.user.uid;
            const userRef = doc(db, 'Users', uid);
            await updateDoc(userRef, {
                newsletter: newsletterChecked
            });


            logEvent(analytics, 'sign_up', {
                method: 'google',
            });
            onClose();
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab label="Register Account" />
                    <Tab label="Login" />
                </Tabs>
            </DialogTitle>
            <DialogContent>
                <Box marginBottom={2} marginTop={2}>
                    <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Box>
                <TextField fullWidth label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                {error && <Box color="error.main" marginTop={2}>{error}</Box>}
                <Box marginTop={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={privacyTOSChecked}
                                onChange={(e) => setPrivacyTOSChecked(e.target.checked)}
                            />
                        }
                        label={
                            <>
                                I accept the{" "}
                                <Link href="/privacy_policy" target="_blank" rel="noopener">
                                    Privacy Policy
                                </Link>{" "}
                                and{" "}
                                <Link href="/terms_of_service" target="_blank" rel="noopener">
                                    Terms of Service
                                </Link>
                            </>
                        }
                    />
                </Box>
                <Box marginTop={0}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newsletterChecked}
                                onChange={(e) => setNewsletterChecked(e.target.checked)}
                            />
                        }
                        label="Subscribe to our newsletter"
                    />
                </Box>
                <Typography
                    color="text.secondary"
                    sx={{ width: "min-content", margin: "auto" }}
                    variant="caption"
                >
                    We do not sell your email to third parties.
                </Typography>
                <GoogleButton
                    variant="outlined"
                    color="primary"
                    onClick={handleGoogleSignIn}
                    style={{ marginTop: '16px', width: "100%" }}
                    disabled={!privacyTOSChecked}
                >
                    Sign in with Google
                </GoogleButton>
                {!privacyTOSChecked && <Typography color="error.main" marginTop={2} sx={{ width: "min-content", margin: "auto" }} variant="caption" >
                    You must accept the Privacy Policy and Terms of Service to continue (even to log in)</Typography>}


            </DialogContent>
            {/* center */}
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={activeTab === 0 ? handleSignUp : handleSignIn}
                    disabled={activeTab === 0 && !privacyTOSChecked}
                >
                    {activeTab === 0 ? 'Sign Up' : 'Sign In'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AuthModal;
