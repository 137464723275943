import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ArchivesPage from './pages/ArchivePage';
import { createContext, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { auth } from './firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getOwnProfile } from './firebase/firebaseCalls';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './components/Profile';
import PricingPage from './pages/PricingPage';
import { CheckoutPage } from './pages/Checkout';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { TermsOfServicePage } from './pages/TermsOfServicePage';
import { UserHistoryPage } from './pages/UserHistoryPage';


export const UserContext = createContext(); //firebase user
export const UserDataContext = createContext(); // firestore user
export const AuthModalContext = createContext();

export const notifySuccess = (message) => toast.success(message);
export const notifyError = (message) => toast.error(message);

function App() {


  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({ votes: {}, credits: 0 });

  const [authModalOpen, setAuthModalOpen] = useState(false);




  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        getOwnProfile(user.uid).then((curUser) => {
          console.log(curUser);
          setUser(user);
          if (curUser) setUserData(curUser);
        }).catch((err) => {
          console.log(err);
        });
      }
    });
    return unsubscribe;
  }, []);


  //every 1 sec log user
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (user === null) return
  //     console.log(user);
  //     console.log(user.data)
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [user]);


  return (

    <Router>
      <UserContext.Provider value={{ user, setUser }}>
        <UserDataContext.Provider value={{ userData: userData, setUserData: setUserData }}>
          <AuthModalContext.Provider value={{ authModalOpen, setAuthModalOpen }}>
            <ToastContainer />

            <Header authModalOpen={authModalOpen} setAuthModalOpen={setAuthModalOpen} />
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/archives" component={ArchivesPage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/profile" component={Profile} />
              <Route path="/pricing" component={PricingPage} />
              <Route path='/checkout' component={CheckoutPage} />
              <Route path="/user_history" component={UserHistoryPage} />

              <Route path="/privacy_policy" component={PrivacyPolicyPage} />
              <Route path="/terms_of_service" component={TermsOfServicePage} />

              <Route path="/404" component={HomePage} />
              <Route path="*" component={HomePage} />

            </Switch>
            <Footer />
          </AuthModalContext.Provider>
        </UserDataContext.Provider>

      </UserContext.Provider>

    </Router>

  );
}

export default App;
