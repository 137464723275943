import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ArrowDownward, ArrowDownwardOutlined, ArrowDownwardTwoTone } from '@mui/icons-material';

export const ScrollDownFab = () => {
    // false if generatedPoemBlock is not visible
    const [visible, setVisible] = useState()

    const handleScroll = () => {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        setVisible(currentScrollPosition <= 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        visible ? (

            <Fab
                color="primary"
                onClick={handleClick}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
                onClick={() => {
                    document.getElementById('generatedPoemBlock').scrollIntoView({ behavior: 'smooth' });
                }}


            >
                <ArrowDownward />
            </Fab>
        ) : null
    );
};

