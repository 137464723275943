import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { auth } from '../firebase/firebase';
import { notifyError, notifySuccess, UserContext } from '../App';
import { deleteUser, sendEmailVerification, updateProfile } from 'firebase/auth';

const UserAccountModal = ({ open, handleClose }) => {
    const { user } = useContext(UserContext);
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    const [displayName, setDisplayName] = useState(user.displayName);
    const [confirmDelete, setConfirmDelete] = useState(false);


    const updateEmail = () => {
        user.updateEmail(email)
            .then(() => {
                sendEmailVerification(user)
                notifySuccess('Email updated and verification email sent.');
            })
            .catch((error) => {
                notifyError(error.message);
            });
    };

    const updatePassword = () => {
        updatePassword(user, password)
            .then(() => {
                notifySuccess('Password updated.');
            })
            .catch((error) => {
                notifyError(error.message);
            });
    };

    const updateDisplayName = () => {
        updateProfile(user, { displayName })
            .then(() => {
                notifySuccess('Display name updated.');
            })
            .catch((error) => {
                notifyError(error.message);
            });
    };

    const deleteUserFromFirebase = () => {
        if (confirmDelete) {
            deleteUser(user)
                .then(() => {
                    notifySuccess('Account deleted.');
                })
                .catch((error) => {
                    notifyError(error.message);
                });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>User Account Management</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Update your email, password, or display name. You can also delete your account.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Password"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Display Name"
                    fullWidth
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={updateEmail}>Update Email</Button>
                <Button onClick={updatePassword}>Update Password</Button>
                <Button onClick={updateDisplayName}>Update Display Name</Button>
                <Button
                    onClick={() => (setConfirmDelete(!confirmDelete))}
                >
                    /!\ Delete Account
                </Button>
                {confirmDelete && (
                    <Button
                        onClick={deleteUser}
                        color="error"
                    >
                        Confirm Delete

                    </Button>
                )}



                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserAccountModal;
