// AboutPage.js
import { Container, Typography, Link, Box, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { analytics } from '../firebase/firebase';
import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';

const AboutPage = () => {

    // analytics
    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'About',
            page_location: window.location.href,
            page_path: window.location.pathname
        });
    }, []);


    return (
        <Container maxWidth="md" sx={{ marginTop: 4 }}>
            <Paper elevation={1} sx={{ padding: 2 }}>
                {/* roboto */}
                <Typography variant="p" gutterBottom sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                    Join the <Link href="https://discord.gg/48z6yNXT" target="_blank">PoetGPT Discord</Link> and the <Link href="https://www.reddit.com/r/PoetGPT/" target="_blank">PoetGPT subreddit</Link> to discuss the app and get help.
                </Typography>
                <br /> <br />
                {/* center */}
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                    FAQ
                </Typography>

                {/* y space between inner elements */}
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">What is this app?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To generate a poem, the app uses OpenAI's ChatGPT API, which is a large language model designed to understand context and produce responses that resemble human writing. The app sends the user's input to the API, which generates a poem based on the given topic. We also use custom prompt engineering techniques to ensure the generation aligns with the user's inputs.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">How does the poem generation work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The poem generation is powered by OpenAI's ChatGPT API, a large language model trained to understand context and generate human-like responses. The application sends the user's input to the API, which generates a poem based on the given topic.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>                <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">How does the acrostic feature works?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                You can generate an acrostic text by entering a sequence in the "Acrostic Word" or "Acrostic Line" fields. The app will then generate a text with the first letter of each line or words matching the letters of the sequence.
                                <br />
                                GPT-4 follows the instructions better than GPT-4 and is far more capable at the "Acrostic Word" feature in english but is goofy in other languages.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h6">How do I generate with GPT-4?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To generate with GPT-4 after your free credits expired, you will need to purchase credits. You can purchase 25 GPT-4 credits for 1€ or 250 for 5€.
                                <br /><br />
                                <strong>New users will receive a bonus of 25 free GPT-4 credits upon sign-up,</strong> which can be used for generating lines without any cost.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h6">How does the GPT-4 Credits works?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                GPT-4 Credits are used to generate with GPT-4. Credits are spent according to the generation size you choose:
                                <br /><br />
                                <ul>
                                    <li><strong>Small</strong> - 1 credit - 1 paragraph</li>
                                    <li><strong>Medium</strong> - 2 credits - 2 paragraphs</li>
                                    <li><strong>Large</strong> - 3 credits - 3-4 paragraphs</li>
                                    <li><strong>XX-Large</strong> - 4 credits - 5-6 paragraphs</li>
                                </ul>
                                <br />
                                <strong>New users will receive a bonus of 25 free GPT-4 credits upon sign-up,</strong> which can be used for generating with GPT-4 without any cost.
                                <br />
                                Keep in mind that ChatGPT and GPT-4 don't always follows the instructions and your generated size may vary.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">How does the critic feature work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                After the generation, you can provide feedback on the AI-generated poem to guide it towards your desired outcome.
                                <br />
                                Keep in mind that you will spend credits for each critic generation if you are using GPT-4.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">Is it free to use?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Using the Chat-GPT API (gpt-3.5-turbo) is free. However, generating with GPT-4 incurs costs.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h6">Can I save my generated poems?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, the app has an Archives page where you can access and save previous generations. You also have the option to add your generation to the public list by clicking on the save button. Furthermore, you can view the history of previous generations for the current session.
                                <br /> <br />
                                <strong>Registered User's poems are automaticaly saved (profile > My History) </strong>
                                <br />  <br />
                                <strong>Unsaved poem from unregistered users will deleted after you leave the page</strong>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Typography variant="h4" gutterBottom sx={{ marginTop: 4, textAlign: 'center' }}>
                    About the Creator
                </Typography>
                <Typography variant="p" gutterBottom sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                    This application was made by{' '}
                    <Link
                        href="https://www.linkedin.com/in/philippe-saad%C3%A9-26972b149/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Philippe Saadé
                    </Link>
                    .
                </Typography>
            </Paper>
        </Container >
    );
};

export default AboutPage;
