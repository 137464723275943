import { Paper } from "@mui/material";
import React from "react";

function PrivacyPolicy() {
    return (
        <div>
            <h1>Privacy Policy for PoetGPT</h1>
            <p>
                At PoetGPT, we are committed to protecting the privacy of our users.
                This Privacy Policy outlines how we collect, use, and protect the personal
                information of our users.
            </p>
            <h2>What information do we collect?</h2>
            <p>
                When you register on PoetGPT, we collect your IP address, display name,
                and email address. This information is necessary to provide you with access
                to our services and to communicate with you regarding your account.
            </p>
            <h2>How do we use your information?</h2>
            <p>
                We use your information to provide you with access to our services, communicate
                with you regarding your account, and to process any payments for services that
                you may purchase. We may also use your information to improve our services, such
                as by analyzing user behavior or trends.
            </p>
            <h2>How do we protect your information?</h2>
            <p>
                We use Firebase services to secure your personal information. Firebase provides
                secure cloud storage and database management, ensuring that your data is protected
                from unauthorized access or use. We also use Stripe to secure any payments made
                through our services.
            </p>
            <h2>Will we share your information with anyone?</h2>
            <p>
                No, we do not share your personal information with any third parties, except as
                required by law.
            </p>
            <h2>Contacting Us:</h2>
            <p>
                If you have any questions or concerns about our privacy policy or how we handle
                your personal information, please contact us at philippe.henri.saade@gmail.com.
            </p>
            <h2>Changes to this Privacy Policy:</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be posted
                on this page, so please review this page periodically to ensure that you are aware
                of any changes. Your continued use of our services after any changes to this Privacy
                Policy indicates your acceptance of the updated policy.
            </p>
            <p>Last Updated: April, 1st, 2023</p>
        </div>
    );
}

function PrivacyPolicyPage() {
    return (
        <div>
            <Paper elevation={3} sx={{ padding: 4, margin: 4 }}>
                <PrivacyPolicy />
            </Paper>
        </div>
    );
}

export default PrivacyPolicyPage;