// ...import statements...
import { Container, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: 4 }}>
            <Typography variant="body2" color="text.secondary">
                &copy; {new Date().getFullYear()} Poem Generator. All rights reserved.{' '}
                <Link href="/terms_of_service" color="inherit">
                    Terms of Services
                </Link>
            </Typography>
        </Container>
    );
};

export default Footer;
