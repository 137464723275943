export const randomTopicList = {
    'en': [
        {
            'poem': 'A myriad of forms, they come in all styles\nBodies diverse, embraced with warm smiles\nCaring, we learn to love each shape and hue\nDelighting in the beauty all people imbue\n\nAll colors and shapes, we celebrate with cheer\nBeautiful inside and out, together we revere\nCurves, lines, uniqueness, we adore\nDiversity in bodies now cherished, forevermore',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Poem about the beauty of diverse bodies and the importance of body positivity.',
            'critics': ''
        },
        {
            'poem': "A better world we must create\nBeneath capitalism's crushing weight\nClear the debris, seize the chance\nDistributing wealth so all enhance\n\nAs one united, we must stand\nBound by ideals, hand in hand\nCreate a future we can all share\nDismantling greed with utmost care",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Song about overthrowing capitalism and building a better world for all.',
            'critics': ''
        },
        {
            'poem': "Ain't it a mess, this world we livin' in, various oppressions begin,\nBattling hate, discrimination, we must learn to blend and amend,\nColor, gender or belief, oppressions be knockin' at our door my friend,\nDon't let it be, change we need to see, our world we must transcend,\n\nAs we rise, with love and our heads held high, let's break the chain,\nBringing change, aware of the pain, let's stand together in our aim,\nCan't ignore, the struggle, the war, the intersectionality to blame,\nDetermined, we know what's in store, our unity will set the world aflame.",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Rap about the intersectionality of different forms of oppression.',
            'critics': ''
        },
        {
            'poem': 'Ardently, I stand by your side, so free,\nBravely embracing the identity you see,\nChampioning the spirit that guides your heart,\nDivinity shining through, a work of art.\n\nAllowing love to flow, unbounded, without measure,\nBeaming acceptance, your strength, your treasure,\nCherishing these moments of love and grace,\nDetermined to keep loving at every pace.',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Love letter to a non-binary partner about embracing their identity.',
            'critics': ''
        },
        {
            'poem': 'A world that once was filled with fear,\nBegging for acceptance, voices we hear,\nCreating unity and breaking free,\nDemanding rights and equality.\n\nA struggle that has gone on so long,\nBut strength and resilience remain strong,\nCourageous hearts fighting for change,\nDignity and love will forever reign.',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Poem about the struggle for LGBTQ+ rights and equality.',
            'critics': ''
        },
        {
            'poem': "All around us, the battle rages\nBroken systems, locked in cages\nCreating change, our voices strong\nDetermined hearts beat like a gong\n\nEquality's our one true goal\nFighting racism, we take control\nGathering together, hand in hand\nHope and justice throughout the land",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Song about fighting against systemic racism and promoting racial justice.',
            'critics': ''
        },
        {
            'poem': "Always breakin' the mold, we ain't playin'\nBustin' the gender myths, no limit to what we're sayin'\nCreate a world where we're all just human beings\nDismantlin' norms as we express our feelings\n\nEmbrace individuality, that's the key\nForget about the labels, just let everyone be\nGender norms crumble as we stand up and resist\nHatred don't define us, love will persist",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Rap about dismantling gender norms and embracing individuality.',
            'critics': ''
        },
        {
            'poem': "Aching hearts we've both endured\nBut love's healing light has assured\nCautious steps turned into strides\nDetermined we stand, hand in hand, side by side\n\nA bond unbreakable, even in strife\nBreaking free, we've acknowledged our life\nCollecting wonders, love conquers fear\nDestiny's rhythm, our hearts dance near",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Love letter to a same-sex partner about the power of love and resilience.',
            'critics': ''
        },
        {
            'poem': "Amidst the vast, expanding universe,\nBeneath the layers of gender and race,\nChampioning intersectionality's diverse base,\nDismantling oppressive structures we traverse.\n\nEach and every one of us plays a part,\nFostering solidarity, our collective heart,\nGaining strength from voices united we will start,\nHarnessing an inclusive feminism to impart.",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Poem about the importance of intersectional feminism.',
            'critics': ''
        },
        {
            'poem': 'A world where labels matter no more\nBreaking down walls, this we implore\nCasting aside the gender binary\nDisregarding norms, finding harmony\n\nEvery soul free to express their heart\nFluidity embraced, a work of art\nGrowth and love, a revolution begun\nHere we stand, united as one',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Song about breaking down the gender binary and embracing fluidity.',
            'critics': ''
        },
        {
            'poem': "Ain't no time to waste, gotta face the truth\nBury the old ways, make space for the youth\nCall for universal healthcare, it's a must\nDeal with inequality, break the chains of trust\n\nEvery worker needs protection, throw the greed out\nFight for their rights, let the peace sprout\nGive a voice to the people, let them all be heard\nHealthcare for all, time to spread the word",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': "Rap about the need for universal healthcare and workers' rights.",
            'critics': ''
        },
        {
            'poem': "A love so pure, like the morning dew\nBeneath this roof, a heart beats for you\nCradled by fate, our paths aligned\nDetermined, I work, to make you mine\n\nAlways together, we will remain\nBuilding our lives, in passion's sweet name\nCounting the stars, as each day ends\nDreaming of us, as more than just friends",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Love letter to a partner from a working-class background.',
            'critics': ''
        },
        {
            'poem': 'Alone we struggle, divided and weak\nBut together we rise, our future not bleak\nCollective action, our voices combined\nDriving change forward, not leaving behind\n\nEmpowered masses, our strength intertwined\nFrom bonds of injustice, we break and unbind\nGathered as one, we fight for our due\nHeeding our call, communities renew',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Poem about the power of collective action and community organizing.',
            'critics': ''
        },
        {
            'poem': "A struggle for rights, for lives anew\nBridges we build to welcome them through\nCause all we need's some love and unity\nDifferences make our colors shine so free\n\nAnd many a heart, in pain, does ache\nBut onward, we fight for humanity's stake\nCreating a world of warmth, and embrace\nDefending our brothers and sisters, we'll face",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Song about the struggle for immigrant rights and inclusivity.',
            'critics': ''
        },
        {
            'poem': "Ayy, we talkin' consent, no resistance \nBusting rape culture, gotta keep the distance \nComing correct with the way that we act \nDismantling hate, that's a matter of fact \n\nAlways ask first, communication's key \nBuildin' trust and respect, don't you see \nConsent is a must, never force or deceive \nDignity and safety, that's what we believe",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Rap about the importance of consent and dismantling rape culture.',
            'critics': ''
        },
        {
            'poem': "A beacon of hope ignites your eyes,\nBasking in your strength, I see beauty arise,\nCurving paths lead to your ever warm embrace,\nDancing with grace, defying life's race.\n\nAgain, I find true love resides within you,\nBoundless spirit, I cherish all that you do,\nCourage and kindness forever your guide,\nDetermined, your heart never will you hide.",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Love letter to a partner with a disability about the beauty of their strength.',
            'critics': ''
        },
        {
            'poem': 'African queens adorned in grace,\nBold, unyielding in the race,\nCourage in their hearts they store,\nDetermined to be so much more.\n\nEmerging from the hands of time,\nFearless they are, so sublime,\nGathered strength like rolling thunder,\nHope igniting them, no more to blunder.',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Poem about the resilience and power of Black women.',
            'critics': ''
        },
        {
            'poem': 'A place to call home, a right we all share\nBurdensome costs, a problem so bare\nCreating solutions, affordable and fair\nDemanding change to show that we care\n\nEveryone needs a roof and four walls \nFor shelter and safety, a dream that befalls \nGiving hope to the ones who feel lost \nHousing for all, achieving this at all cost',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Song about the need for affordable housing and the right to shelter.',
            'critics': ''
        },
        {
            'poem': "Ain't no shame in talking 'bout the struggles in our mind\nBattles we face every day, ain't something we can bind\nCan't let stigma hold us down, gotta voice our realest fears\nDetermined for solutions, gotta fight those taunting jeers\n\nAid each other through the darkest moments that we face\nBelieving in recovery, this pain we can displace\nCrashing through the barriers, we stand up strong and tall\nDemolishing the stigma, we can help each other fall",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Rap about the importance of mental health care and destigmatization.',
            'critics': ''
        },
        {
            'poem': 'A band of friends we’ve gathered here,\nBlessed to grow our family so dear,\nCherished moments we’ve treasured,\nDelightful memories shared and measured.\n\nAgain, we come together as one,\nBuilding bonds, our hearts have won,\nConnected in this love so true,\nDear chosen family, I cherish you.',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Love letter to a partner about the joy of chosen family and community.',
            'critics': ''
        },
        {
            'poem': 'Amidst the forests and the plains,\nBeings who know their rightful claims,\nCradled by the earth, they stand,\nDefending their ancestral land.\n\nEchoes of the stories told,\nForged by battles brave and bold,\nGuardians of the lands they love,\nHonoring the skies above.',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Poem about the importance of Indigenous sovereignty and land rights.',
            'critics': ''
        },
        {
            'poem': "Ain't no way to justify the pain, we unite \nBattles we fight against the system of the night \nCrushing the oppressors who try to block the light \nDemanding justice for our people, that's our right \n\nEnough of brutality, we rise in solidarity \nFierce hearts we carry, for change we won't tarry \nGotta dismantle the chains that the prisons carry \nHeal the hurt of our people, our vision can't be blurry",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Song about fighting against police brutality and the prison-industrial complex.',
            'critics': ''
        },
        {
            'poem': "A utonomy for all, that's the goal, we stress\nB odily integrity, we fight for progress\nC hoices we deserve, reproductive rights we profess\nD elegating power to know what's best, no less\n\nA ccess to care ain't something to dismiss\nB etter healthcare, respect and fairness we insist\nC onnected we stand, no choice we will miss\nD efending our rights, in this fight we'll persist",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Rap about the need for reproductive justice and bodily autonomy.',
            'critics': ''
        },
        {
            'poem': "Aging gracefully, we embrace our time,\nBrimming with love, in this life so sublime.\nCherishing every wrinkle and line,\nDancing together as our souls intertwine.\n\nEternal connection, we'll never grow cold,\nForever in love as our story unfolds.\nGrowing nearer, our hearts, they entwine,\nHand in hand, let us grow old, divine.",
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Love letter to a partner about the beauty of aging and growing old together.',
            'critics': ''
        },
        {
            'poem': 'All together now, we stand as one,\nBound by hope, guided by the sun.\nCries for justice echo through the ages,\nDetermined allies, united, turn the pages.\n\nAgain we rise, expanding our reach,\nBeyond divisions, our voices preach.\nCreating change, we build a new day,\nDetermined, strong, we journey our way.',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': 'Poem about the power of solidarity and allyship in the fight for social justice.',
            'critics': ''
        },
        {
            'poem': 'Astonishing acts of affection arise,\nBenevolent beings bestow blessings, I apprise,\nCherished connections coalesce, love thrives,\nDoting dances delight, fluttering jives.\n\nAgain, adventures abide, artistically aspiring,\nBountiful ballads buoyantly breathing, inspiring,\nCaptivating choruses, charming chimes interweaving,\nDelightful days descend, dreams manifest, achieving.',
            'topic': '',
            'acrosticWord': '',
            'acrosticLine': ' ',
            'language': 'en',
            'poemSize': 8,
            'model': 'gpt4',
            'title': '',
            'critics': ''
        }]
    ,

    "qc": [
        {
            "poem": "Dans' jobine, les femmes sont souvent cal\u00e9es,\nOn les voit au bas d'l'\u00e9chelle, sans s'garocher,\nLes gars, eux, sont \u00e9lev\u00e9s comme des rois,\nPis les filles trop souvent r'jet\u00e9es, d\u00e9laiss\u00e9es.\n\nY faut qu'\u00e7a change, cette maudite iniquit\u00e9,\nBrasser la cage et chasser ces calvaires d'id\u00e9es,\nCar dans c'temps-ci, homme ou femme faut s'l'ver le bec,\nPour faire sa place et combattre c't'in\u00e9galit\u00e9, au sec!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me d\u00e9non\u00e7ant l'in\u00e9galit\u00e9 des genres dans le monde du travail, les st\u00e9r\u00e9otypes persistants.",
            "critics": ""
        },
        {
            "poem": "J'vais vous parler d'un syst\u00e8me qui cr\u00e9e des calvaires,\nDes entreprises qui exploitent les ouvriers sans fronti\u00e8res,\nCes boss-l\u00e0 sont des hosties d'crosseurs, qu'y aillent donc chier,\nC'est l'temps d'la r\u00e9volution, faut s'unir pour se lib\u00e9rer.\n\nFini l'temps des tabarnaks de profiteurs sans piti\u00e9,\nOn va changer la game, renverser c'te soci\u00e9t\u00e9,\nEnsemble, main dans la main, on s'battra sans concession,\nVive la r\u00e9volution ouvri\u00e8re, notre vraie redomption.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap critiquant les entreprises exploitant les prol\u00e9taires, appelant \u00e0 la r\u00e9volution ouvri\u00e8re.",
            "critics": ""
        },
        {
            "poem": "Yo, Macron, tu penses \u00eatre un saint ciboire,\nMais t'as mis les retraites en p\u00e9ril, on est dans l'noir,\nTabarnak, nos vieux sont crott\u00e9s, leurs pensions rikiki,\nT'as laiss\u00e9 les travailleurs dans l'mar\u00e9cage, quel sacr\u00e9 tataki!\n\nChaque jour, on s'bousille la sant\u00e9, c\u00e2line d'abord,\nPour finir dans la mouise, sans p\u00e9cunes, c'est d\u00e9bordant de torts,\nY'en a assez de tes conneries, ti-bidon de politicien,\nQu'on arrache c'taquine d'retraite pourris, parole de Qu\u00e9b\u00e9quien!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre Macron pour sa politique de retraite, d\u00e9crivant les cons\u00e9quences sur les travailleurs.",
            "critics": ""
        },
        {
            "poem": "\u00d4 chers camarades, tabernacle de ma vie,\nEnsemble, manifestons pour une destin\u00e9e en joie,\nLa solidarit\u00e9 nous d\u00e9lie des tracas, des p\u00e9pins,\nUnissant nos c\u0153urs Bat\u00e8ches, vers un monde de bon aloi,\n\nDans les ruelles de Montr\u00e9al en poudrerie,\nMain dans la main, nous luttons tous, mes amis,\nPour un futur tendre, sortant du chnoute et du noir,\nOste tes \u0153ill\u00e8res, et vois le fruit de notre espoir.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 la solidarit\u00e9 entre les manifestants, c\u00e9l\u00e9brant leur unit\u00e9 pour un futur meilleur.",
            "critics": ""
        },
        {
            "poem": "Dans c'te monde de menteries, que de diversit\u00e9s,\nLes chums et les blondes, pis toutes les vari\u00e9t\u00e9s.\nY'en a c'qui se cherchent, d'autres ben \u00e9pris,\nC\u00e2lins ou papillon, que l'amour les r\u00e9unis.\n\nAstheure, faut s'aimer, pis brasser d'la couleur,\nLes tapettes, les gouines, accroch\u00e9es su' le c\u0153ur,\nAccepter ses semblables, tel qu'au bon vieux temps,\nPis aimer \u00e0 bras ouverts, en respectant les gens.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur l'importance de respecter toutes les orientations sexuelles, appelant \u00e0 l'acceptation et l'amour.",
            "critics": ""
        },
        {
            "poem": "Yo, dans la bataille pour l'\u00e9galit\u00e9, les femmes en tabarouette,\nElles luttent fort, main dans la main, brisant les barouettes,\nC'est vrai, y'a encore des esti d'obstacles sur l'boulevard,\nMais on s'bat pour nos droits sans \u00eatre magan\u00e9e ni baveux envers l'autre bord.\n\nGr\u00e2ce au f\u00e9minisme, on avance un peu plus chaque jour,\nLes victoires se multiplient, montrant aux femmes qu'on est d'la partie, pas juste pour faire ton cour,\nEnsemble on \u00e9clate les tocsins, on s'\u00e9l\u00e8ve pour qu'\u00e7a change au Qu\u00e9bec et ailleurs,\nLes d\u00e9fis sont nombreux, mais les femmes sont fortes, pis elles persistents avec bonheur.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur la lutte des femmes pour l'\u00e9galit\u00e9, soulignant leurs victoires et d\u00e9fis dans le f\u00e9minisme.",
            "critics": ""
        },
        {
            "poem": "Tabarnak, \u00e7a m'fait chier c't'absence d'revenu,\nCriss de syst\u00e8me qu'nous met d'vant l'menu,\nLa pr\u00e9carit\u00e9 s'propage, c'est ben grave,\nJ'me suis tu\u00e9 l'dos \u00e0 jongler mes jobines br\u00e8ve.\n\nCalvasse, on perd la sant\u00e9, \u00e7a m'\u00e9c\u0153ure,\nJ'r\u00eave d'un jour o\u00f9 l'UBI serait l'bonheur,\nOn m\u00e9rite d'vivre et pas juste survivre,\nOn attend, nous les p'tits Qu\u00e9b\u00e9cois ivres.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track critiquant la pr\u00e9carit\u00e9 caus\u00e9e par le manque de revenu de base universel.",
            "critics": ""
        },
        {
            "poem": "Chers d\u00e9fenseurs, \u00eatres si formidables,\nJ'vous \u00e9cris pour vous car vous \u00eates capables,\nD'nous prot\u00e9ger \u00e0 l'\u00e2ge o\u00f9 l'\u00e9nergie s'tire,\nEn vous battant sans cesse pour nos sourires.\n\nOn vous r'mercie, mes p'tits choux fleuris,\nD'faire valser nos c\u0153urs avec tant d'esprits,\nL\u00e2chez pas l'morceau, nos fid\u00e8les amis,\nCar gr\u00e2ce \u00e0 vous, la vieillesse est un joli paradis.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux d\u00e9fenseurs du droit \u00e0 la retraite, exprimant gratitude et soutien.",
            "critics": ""
        },
        {
            "poem": "Dans un monde o\u00f9 les boss pailles nos vies,\nNoyer sous le joug, le prol\u00e9taire survit,\nMais un jour d'espoir, s'\u00e9veill'ront les pl\u00e8bes,\nPour consacrer ensemble un' r\u00e9volution j'n\u00e8be.\n\nQue grandisse fort cette id\u00e9e en nos c\u0153urs,\nS'enracin'ntoint'l\u00e0 endurcirons les ardeurs,\nVers un monde plus juste et porteur d'avenir,\n\u00c7a prendra lar'd\u00e9pa, mouskaillon on va grandir!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me exaltant les espoirs d'un monde plus juste gr\u00e2ce \u00e0 la r\u00e9volution des prol\u00e9taires.",
            "critics": ""
        },
        {
            "poem": "Yo, dans la rue on manifeste, c'est pas de la frime,\nPour les droits des LGBTQ+, ensemble on s'anime,\nIcitte au Qu\u00e9bec, on se bat pour l'amour sinc\u00e8re,\nY'a pas d'chicane, mate nos couleurs, on est fiers, tabarnak!\n\nDans la lutte on pers\u00e9v\u00e8re, on monte au cr\u00e9neau,\nCar on sait qu'ensemble on est forts, c'est pas d'la bullshit, c'est du beau,\nPour que chacun ait sa voie, sa place, avec respect,\nDans le coeur des gens, on veut semer l'\u00e9galit\u00e9, en toute libert\u00e9!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur les manifestations pour les droits LGBTQ+, soulignant leur courage et pers\u00e9v\u00e9rance.",
            "critics": ""
        },
        {
            "poem": "J'vois les caveaux ben garnis, d'la flotte pour les riches\nMais y'a faim d'loup dans nos ventres, s'pas ficelles pis r\u00e9gime\nLe fric s'accumule dans le West, coudonc, j'veux-tu ma tarte?\nOn s'casse le b\u00e9cik, on char en c\u00e2lisse, pis eux en Maserati\n\nLa vie est ben trop croche, un \u00e9cart qui s'\u00e9largit\nLes fins mo\u00e9 dans le d\u00e9duit, retourne \u00e0 l'\u00e9poque des pharaons\nOn demande juste une vie d'choeur pour tous ici-bas\nCrisse c't' injustice \u00e9conomique qui nous ronge jusqu'au bast",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track d\u00e9non\u00e7ant l'injustice \u00e9conomique et l'\u00e9cart grandissant entre riches et pauvres.",
            "critics": ""
        },
        {
            "poem": "\u00d4 fervents d\u00e9fenseurs du revenu de base,\nDans mon coeur, votre cause brille telle une turlutte,\nVotre noble qu\u00eate pour un avenir durable et equitable,\nFait vibrer mon \u00e2me comme un doux p\u00e2t\u00e9 chinois sur la table.\n\nAinsi les cieux et les tabarnaks qu\u00e9b\u00e9cois,\nS'unissent pour b\u00e9nir vos nobles exploits,\nQue nos r\u00eaves d'\u00e9quit\u00e9 enfin s'\u00e9panouissent,\nEt que notre espoir en un monde meilleur, s'accomplisse.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 ceux qui luttent pour un revenu de base universel, r\u00eavant d'une soci\u00e9t\u00e9 \u00e9quitable.",
            "critics": ""
        },
        {
            "poem": "Au combat des travailleurs, tabarnak qu\u00e9b\u00e9cois,\nOn lutte contre le recul, virant capot d'angoisse,\nLes maudites ann\u00e9es s'additionnent dret su'l'froid,\nLe temps file \u00e0 la retraite, c'paquet d'caliceois.\n\nNe l\u00e2chons pas les gars, astheure 'faut s'tenir,\nFaut pliera l'\u00e9chine et combattre l'avenir,\nEsp\u00e9rant que nos coeurs de qu\u00e9b\u00e9cois s'tans'tissent,\nPis qu'la retraite tant\u00f4t n'soit pas qu'une p\u00e9pite.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur le combat des travailleurs contre le recul de l'\u00e2ge de la retraite.",
            "critics": ""
        },
        {
            "poem": "Dans c't'univers color\u00e9, chus l\u00e0 pour c\u00e9l\u00e9brer,\nLa diversit\u00e9 des genres, on va s'respecter,\nPis les st\u00e9r\u00e9otypes, on s'met \u00e0 les briser,\nTabarnak, ostie, assez d'juger sans r'garder.\n\nDans l'patois qu\u00e9b\u00e9cois, j'trouve des mots pour m'exprimer.\nChante aux curieux d'apprendre, aux gens \u00e0 s'tromper,\nCrisse qu'on est ben proche d'un monde \u00e0 changer,\nClaquer des doigts, c'est tout c'qu'on va essayer.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap c\u00e9l\u00e9brant la diversit\u00e9 des genres et l'importance de briser les st\u00e9r\u00e9otypes.",
            "critics": ""
        },
        {
            "poem": "Yo, les politiciens, z\u2019\u00eates ben mal inspir\u00e9s,\nD'ignorer nos cris, nous faire carr\u00e9ment chier,\nOn s'\u00e9pivarde, mais vous restez sourds nos tabarnaks,\nCriss de menteurs, cessez vos sacres de m'arnaques,\n\nOn est tann\u00e9s, esti qu'on veut \u00eatre entendus,\nC\u00e2lisse, arr\u00eatez d'nous prendre pour des perdus,\nTout' votr' clique, \u00e7a va bien mal finir,\nSi \u00e7a continue, on va tous vous d\u00e9peupler, d\u00e9chirer.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track moquant les politiciens qui ignorent les revendications des manifestants.",
            "critics": ""
        },
        {
            "poem": "Mes ch\u00e8res soeurettes en batailles,\nJe vous \u00e9cris avec amour de la belle Gatineau,\nVotre combat pour nos droits, c'est l'\u00e9quilibre sur la pirogue,\nCoudonc, s\u00e9rieux, vous \u00eates dans ma caboche comme un megots\n\nGr\u00e2ce \u00e0 vous, je r\u00eave d'un monde \u00e0 la Babette,\nO\u00f9 l'\u00e9galit\u00e9 rime avec su'l fly\u00e9, sans aucune cossette,\nPour l'\u00e9quit\u00e9 entre tou\u00e9pis et chum de la terre,\nDi\u00e9gelez, et scions continuez dans notre combat sol\u00e8aiare",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux militants f\u00e9ministes, remerciant leur engagement pour l'\u00e9galit\u00e9 des sexes.",
            "critics": ""
        },
        {
            "poem": "Oh\u00e9 les gars, ralliez-vous \u00e0 la bataille,\nPour un monde meilleur, on s'met en branle,\nLes prol\u00e9taires unis, guid\u00e9s par leur flamme,\nAvec leurs frissons de cris, chemin en tabl\u00e9e.\n\nQue chaque tabarnak se l\u00e8ve et s'\u00e9poumone,\nL'avenir d'un peuple ne s'tresse pas d'chiffons,\nOn r\u00eave d'une r\u00e9volution qui crie d'lumi\u00e8res,\nLes m\u00e9t\u00e8ques ont d'bords, ensemble on brisera les cordes.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la force collective des prol\u00e9taires unis pour une r\u00e9volution sociale.",
            "critics": ""
        },
        {
            "poem": "J'viens du Qu\u00e9bec et j'rap pour la retraite, en patois faut qu'\u00e7a p\u00e8te,\nC't'urgence d'agir, c\u00e2line faut pas \u00eatre t\u00eate de linotte,\nPis convaincre le peuple, notre fight est frette,\nJ'd\u00e9fends nos a\u00een\u00e9s, tabarnak faut pas qu'on radote,\n\nNot' pension c'est not' butin, hostie faut qu'on s'accroche,\nGarde la p\u00eache, rel\u00e8ve-toi, notre combat est noble,\nLes bs vs travailleurs, faut s'unir, \u00eatre proche,\nJ'veux des droits prot\u00e9g\u00e9s, pis pas \u00eatre dans le gros lotte.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap d\u00e9crivant la lutte pour la protection du droit \u00e0 la retraite, insistant sur l'urgence d'agir.",
            "critics": ""
        },
        {
            "poem": "Yo, les bourgeois oppresseurs, vos profits insens\u00e9s,\nSont faits su'l dos des ouvriers, toute la journ\u00e9e.\nAvec vot' capitalisme pis vos mains dans les poches,\nOsti qu'on en arrache, qu'on vit en sous-cloches.\n\nC'est l' char pis la pitoune, le qu\u00e9b\u00e9cois d\u00e9laiss\u00e9,\nOn travaille \u00e0 s'en \u00e9reinter, pendant qu'vous investissez.\nL\u00e2chez donc les machines, embarquez dans not' canot,\nEnsemble on pourra b\u00e2tir un monde id\u00e9al, su'l m\u00eame bateau.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track critiquant l'oppression des classes ouvri\u00e8res par le capitalisme.",
            "critics": ""
        },
        {
            "poem": "Ch\u00e8re fraternit\u00e9, cordiale amiti\u00e9,\nUnissant nos luttes, c'est la libert\u00e9,\nMinorit\u00e9s et travailleurs, main dans la main,\nEn patois qu\u00e9b\u00e9cois, chantons ce refrain.\n\nTabernacle de t\u00e8tes carr\u00e9es, on avance,\nDe pis en pis, on d\u00e9fend notre chance,\nCalice de l'union, tel un phare, rutilant,\nAu nom des opprim\u00e9s, ensemble, r\u00e9sistants.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 la fraternit\u00e9 entre les luttes pour les droits des minorit\u00e9s et les droits des travailleurs.",
            "critics": ""
        },
        {
            "poem": "Dans la vaste contr\u00e9e de la sainte-flanelle,\nChacun vit sa vie, coeur pur ou rebelle,\nTous fiers, tous fous, ils se tiennent main dans la main,\nTabarslac, nous sommes nous, telle est notre destin.\n\nB\u00e9deilles et galettes, rotteux d'bois aimants,\nQu'importe le sarrau ou qu'importe le penchant,\n\u00d4 tabarnouche, soyez donc guillerets,\nCar \u00eatre soi, c'est dans la diff\u00e9rence qu'on s'f\u00eate.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la fiert\u00e9 d'\u00eatre soi, ind\u00e9pendamment de son genre ou orientation sexuelle.",
            "critics": ""
        },
        {
            "poem": "Dans ce rap, j'viens d\u00e9noncer les violences, ostie,\nContre les femmes, faut pas rester silencieux, tabarouette,\nL\u00e8ve-toi pour les soutenir, l\u00e2che pas la patente,\nLe f\u00e9minisme c'est important, comprends \u00e7a, c\u00e2lice!\n\nLes filles, faut les prot\u00e9ger, pas les blesser, crisse,\nPas de mains baladeuses, pas de mots pesants, sapristi,\nOn veut la paix, l'\u00e9galit\u00e9, faut que \u00e7a s'mette en place,\nTous ensemble pour la cause, rien \u00e0 foutre des pr\u00e9jug\u00e9s, esti!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap d\u00e9non\u00e7ant les violences faites aux femmes et l'importance du f\u00e9minisme.",
            "critics": ""
        },
        {
            "poem": "Hey, les boss \u00e0 l'esprit tordu, \nCessez de jeter des regards baveux.\nOn veut un taf dans l'm\u00eame bateau,\nPas de larguage pour nos fr\u00e8res LGBTQ+ !\n\nSoyez pas cave, c'est pas un choix,\nOn m\u00e9rite autant les jobs que toi.\n\u00c9coutez donc, on veut qu'\u00e9galit\u00e9,\nDans l'boulot, c'est l'talent qui doit pr\u00e9sider.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre la discrimination envers les personnes LGBTQ+ dans le monde du travail.",
            "critics": ""
        },
        {
            "poem": "Mes chers \u00eatres de lumi\u00e8re, tou\u00e9 qui lutte pour l'\u00e9galit\u00e9,\nVos efforts, sacr\u00e9s totons, offre une belle r\u00e9alit\u00e9.\nAu nom du peuple qu\u00e9b\u00e9cois, on eshiera nos b\u00e9cosses, \nL'peine vaut le trouble, astheure, pour d\u00e9faire ces grosses bosses.\n\nOn se bat bras dessus, bras d'ssous, v'nez don piquer une jasette,\nEn marchant t\u00eate haute, l'patente, avec amour c'est frette!\nPour nos p'tits touts carreaut\u00e9s, l'\u00e9galit\u00e9 faut qui-colle,\nVos actions sont nostalgiques, c't \u00e7a qui compte, mettons!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 ceux qui luttent pour un monde plus inclusif et \u00e9galitaire.",
            "critics": ""
        },
        {
            "poem": "Ensembl' on s'tient, les camarades,\nLa main dans la main, sans barricades,\nTabarnaqu' un avenir meilleur\nOn gagn'ra fort, avec not' coeur.\n\nSolidaires qu\u00e9b\u00e9cois, dans la rue\nNot' linge sale aux vents suspendu,\nTous pour un, chacun sa part, pas d'chican'\nContre l'soleil noir, rallumons nos lampions.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la solidarit\u00e9 entre les manifestants pour un avenir meilleur.",
            "critics": ""
        },
        {
            "poem": "\u00c9coutez, tabarnak, c'est l'heure du changement,\nUn revenu d'base pour calmer nos tourments,\nTous ces estis d'pauvres, c'est vraiment d\u00e9brouillard,\nMais la pr\u00e9carit\u00e9 leur met toujours un brouillard.\n\nFaudrait s'r\u00e9veiller, mon criss d'\u00e9pais,\nCe syst\u00e8me-l\u00e0, osti, yen a pour tous les cas,\nR'garde le esti d'progr\u00e8s qu'on pourrait faire,\nAvec un revenu d'base, criss, plus d'fronti\u00e8res!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur la n\u00e9cessit\u00e9 de mettre en place un revenu de base universel pour r\u00e9duire la pr\u00e9carit\u00e9.",
            "critics": ""
        },
        {
            "poem": "Asteur, j'vous l'dis, c't'assez, on a l'tour,\nInaction politique, in\u00e9galit\u00e9s d'tous les jours,\nTab'nac, nos \u00e9lus y s'en calicent ben trop,\n\u00c0 s'parker l\u2019cul dans leurs chars pendant qu'nous on est \u00e0 v\u00e9lo,\n\nS'font des gueul'rons d'la faim, mais nous autres, rien qu'du vent\nY'a pus d'oseille pour l'monde, l'gouvernement y nous prend pour des perdants,\nLe temps passe, mais calvaire, rien qu'cyant,\nLes grandes promesses, d'la peau d'bearse en attendant.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre l'inaction politique face aux in\u00e9galit\u00e9s sociales et \u00e9conomiques.",
            "critics": ""
        },
        {
            "poem": "Chers activistes, c\u0153ur vaillant et beau,\nEn patois qu\u00e9b\u00e9cois, je vous dis bravo,\nLa plug, la puck, c'est gr\u00e2ce \u00e0 vous autres,\nQue la tabarouette d'injustice se vautre.\n\nM\u00eame si parfois c'est raide, long comme bras de Morfalou,\nPersistez et signez, au nom des siens, des fous,\nLuttons ensemble jusqu\u2019\u00e0 la p'tite semaine,\nPour un monde meilleur, rempli d\u2019amour, que l\u2019on m\u00e8ne.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux activistes qui \u0153uvrent pour un monde plus juste et \u00e9quitable.",
            "critics": ""
        },
        {
            "poem": "Aux quatre coins d'la belle province,\nTous unis, on brave les tabarnacs.\nChacun s'bat en s'donnant d'la mainmoune,\nPour l'av'nir, on monte au cr\u00e9neau c\u00e2l\u00een.\n\nDes Patriotes jusqu'au plus y\u00e2be,\n\u00c0 Montr\u00e9al ou dans l'rang \u00e0 pins,\nC'est avec coeur que nos luttes s'enflamment,\nPour droit humain, on gard' l'cap, sans flancher l'\u00e9pin.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me c\u00e9l\u00e9brant la diversit\u00e9 des luttes pour les droits humains, unies pour un avenir meilleur.",
            "critics": ""
        }
    ],

    "ct": [
        {
            "poem": "Dins ch' monde du traval, in voait co\u00e9r des in\u00e9qalit\u00e9s,\nFillasses et gar\u00e7ons, foaites \u00e9gards, ch'est acore \u00e5w\u00e5t\u00e9,\nCh' patron y fait s'b\u00e9stiau, tout ducasse et riboteux,\nAs dames i' dechi\u00e8tent cheu coeur, mais qu' wak'te don l' yeux.\n\nO fillettes d'Picardie, ram\u00e9neuzes d'soupe, in \u00eates pus duch'mint,\nHiercheuzes d' d\u00e9s, t\u00e8y\u00e8nt l'b\u00e9nier, vos bizarreries qu'est eune uf\u00e9min't,\nIl est temps d'qu\u00e9quetter l'barque, pi proclaimer vos dreuts,\nFaud blanc et laut foait draie, l'in\u00e9qalit\u00e9 i' g\u00e9s deut.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me d\u00e9non\u00e7ant l'in\u00e9galit\u00e9 des genres dans le monde du travail, les st\u00e9r\u00e9otypes persistants.",
            "critics": ""
        },
        {
            "poem": "Les babines \u00e0 billets, y vol'tent nos r\u00eavailles,\nInexploitation, ch'est nous qu'y s'ont fra\u00eech' mis\u00e8re,\nFaudot qu'les chinq Mars de'nou fass'nt \u00e9ter' d\u00e9' l'terre,\nAstin pabo mollettes, vleu d'la r\u00e9volution!\n\nSoumis dus lis fabraques, d'la chit\u00e9 d\u00e9s myons,\nR\u00e9voltons-euch, ouvr\u00e9ch\u00e9, chti tiemps n'nos piche poind'ra,\nCh\u00e9s chiminots, al d\u00e9mersez nos fran\u00e7aises labours,\nA c'muche m\u00e8l \u00e0 l\u00e8que, cr\u00e8fot po d'p\u00e9rouse chiatt'ra.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap critiquant les entreprises exploitant les prol\u00e9taires, appelant \u00e0 la r\u00e9volution ouvri\u00e8re.",
            "critics": ""
        },
        {
            "poem": "Macron, l\u00e0-haut dins s'tour d'ivoire, qu'mintux qui chamboule tout,\nIn n'vot punto d'ch\u00f4mage, mais qu'i veut raboter l'bout,\nI'archonnais pus s'ti-pan, i'creux des qui\u00e8res dins no foi,\nDins tin monde pinchard, in picard, dis-le eune fos : F... toi!\n\nLibre \u00e0 ti de bachuquer nos jours sans amorosse,\nEmbeute bin, din l'museau qu't'as, tout's nos querelles caraboss\u00e9es,\nI'faudrot pro qu't'\u00e9coutes ches gins d'ichi, ches pleudeux,\nEt qu'tu r\u00e9alis'qu'ton invit' aux trint' glorieus'' est tardiveux.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre Macron pour sa politique de retraite, d\u00e9crivant les cons\u00e9quences sur les travailleurs.",
            "critics": ""
        },
        {
            "poem": "Amis manifestants, in vos inbrasse fort,\nMi, ch'est el Picard, l'amour j'ravisse \u00e0 bord,\nApr\u00e8s ch\u00e9s mornes, on s'rot bin ch\u00e9 toudis,\nInsembl' v\u00e8r el futur, unis comme des zadis.\n\nClamons l'victoire, main dins l'main sans falloir,\nSolidaires, loin de ces guindailleurs d'espoir,\nIn vos jurant fid\u00e9lit\u00e9 jusqu'au bistreux,\nNous glorifions nos valeurs, du blond au chinchireux.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 la solidarit\u00e9 entre les manifestants, c\u00e9l\u00e9brant leur unit\u00e9 pour un futur meilleur.",
            "critics": ""
        },
        {
            "poem": "Dins ch\u00e9s c\u0153urs, chacun s'amourache,\nQu'ch't'\u00f4te ou maquerelle, amix ou pl\u00e9vinache,\nDins du p\u00e2tis picard, nous in sourit.\nSu ch\u00e9s territoires d'accepta\u00e7on, all s'elargit.\n\nN'y'a point d'belangue, n\u00e9 y'a point d'tria,\nN'y'a qu'l'amour qu'\u00e0 travers ch\u00e9s lignes ria.\nLaissons nos tch\u0153urs vi\u00e8re, suivre ch\u00e9s attrait,\nRespectons toutes les id\u00e9es, \u0153uvrons pour l'amour partag\u00e9.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur l'importance de respecter toutes les orientations sexuelles, appelant \u00e0 l'acceptation et l'amour.",
            "critics": ""
        },
        {
            "poem": "Yo, fillettes et berlouffes, on a du gadouille \u00e0 conte,\nPartout en Amouonie, parmi les femelles qui montent,\nCh'est l'bataye pour \u00e9galit\u00e9, des victoires on a eu,\nCoum' Marie Curie, Nobel, i-n-a d'ja qu'elle \u00e9 ch'ju.\n\nEj continue \u00e0 min camarade, coum' Simone de Beauvoir,\nCh' f\u00e9minichte lucratif, raviche donc ch' fichtoir!\nN'obliez point les challinges, les luts du pass\u00e9,\nPou not' avenir tout en rose, in peut core s'battre et avincher.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur la lutte des femmes pour l'\u00e9galit\u00e9, soulignant leurs victoires et d\u00e9fis dans le f\u00e9minisme.",
            "critics": ""
        },
        {
            "poem": "Yo!, ches parchons sont laich\u00e9 d'\u00e9plo!\nLa vie sans ch'revenu, ch'est comme ki braulo,\nIn est dins l'mis\u00e8re, point d'quener \u00e0 pie',\nTous coinc\u00e9s dins fun'st\u00e8re, erd\u00e9s comme un pie'.\n\nA'berloque l'society, yeux point d'galerie,\nAucun d'entre vous saqu\u00e9, in train d'l'utiliser.\nI's fos qu'on met el' paquet, sul'vier route on cache,\nErchevons dins l'pouvoir, pour ram'ner ch'te gadache!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track critiquant la pr\u00e9carit\u00e9 caus\u00e9e par le manque de revenu de base universel.",
            "critics": ""
        },
        {
            "poem": "Ch\u00e9s d\u00e9fenseurs du droit, \u00e0 vot' bel' \u00e2me j'd\u00e9clare,\nAveuc des mots d'chez nous, du Picard qu'on pr\u00e9f\u00e8re.\nDins ch\u00e9s temps dificiles, au coeur d'la bataille,\nAuch'teur, como un diams, vot' bravoure braille.\n\nJ'rinds gr\u00e2ce pi j'sus fi\u00e8re, tout comme ch\u00e9s chiens d'mousique,\nQu'poussent tirelires al' retraite, pi j'vous pr\u00e9sente m\u00e9 coperlique!\nSo'tenus dins nos coeurs, vot' combat est b\u00e9nais,\nM\u00e9racoteux d\u00e9fenseurs, nouv' v'ous disons merci.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux d\u00e9fenseurs du droit \u00e0 la retraite, exprimant gratitude et soutien.",
            "critics": ""
        },
        {
            "poem": "Au tournant d'un r\u00e9veil, ch\u00e9s prol\u00e9taires s'\u00e9l\u00e8vent\nPou un monde pus juste, in unit\u00e9 sans tr\u00e8ve\nIn picard, nos racin's, du fond d'nos quartiers\nCont' l'injustice, nos c\u0153urs d'acier vont lutter\n\nAspirant \u00e0 l'espoir, d'nouvieaux jours d'\u00e9gal't\u00e9\nIn r\u00eavons d'abatt' ch'l'\u00e9dile patronn' et concret\nLes conflits finiront, ch\u00e9s ouvriers gustant victoire\nIn r\u00e9coltons l'fruit, d'c\u00e9 r\u00e9v'lutionnaire histoire",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me exaltant les espoirs d'un monde plus juste gr\u00e2ce \u00e0 la r\u00e9volution des prol\u00e9taires.",
            "critics": ""
        },
        {
            "poem": "O\u00e9 les militanteux d'\u00e9galit\u00e9 d'core,\nMi\u00e9 j'arpr\u00e9sinte les droits d'ces jins-l\u00f2,\nIn rue chinquante-cinch not' pabu parait,\nAsteur les dragons d'chu patois tient l'frait.\n\nArm\u00e9s \u00e9d courage pis del pers\u00e9v\u00e9rance,\nMilitant rimplichi \u00e9d braf guide\u00fbse\u00fbx,\nIn arskrivant les maux d'inju', \u00e9d violence,\nSerrons les rinchognux, les peinturl\u00e9\u00fbx l\u00e9be\u00fbx.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur les manifestations pour les droits LGBTQ+, soulignant leur courage et pers\u00e9v\u00e9rance.",
            "critics": ""
        },
        {
            "poem": "Yo, ch\u00e9s gros richards i s'la p\u00e8tent, i nous vol'tit l'futur,\nTandis qu'in trim' din chl'omb' avuc nos raches au mur.\nAstiqu'tons nos baskets, gailloch' su'l trottoir,\nN'\u00eatre ni ma\u00eetres ni riches, mais bitch, ch'ta le pouvoir !\n\nMi j'vois l'\u00e9cart s'carfer, ch\u00e9s riches \u00e0 Gavrelle,\nCh\u00e9s mis\u00e9rats \u00e0 Floyon, aveuque ch'faim qui nou s'rapelle.\nFaiseu que choss' insemb', div'rions ch\u00e9s billets,\nBin s\u00fbr mi j'sus n'cor quoi, mais ichi j'su l'rappieur des oubliets.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track d\u00e9non\u00e7ant l'injustice \u00e9conomique et l'\u00e9cart grandissant entre riches et pauvres.",
            "critics": ""
        },
        {
            "poem": "Chers ad\u00e9fenseurs d'eun' cause noble et bio,\nComme aimer dins ch' patois qu'min tchot ch\u0153ur invoque,\nPou l'revenu d'base, \u00e0 ch'mincho vous diot bravo,\nIn r\u00eavant d'eune soci\u00e9t\u00e9, toudis pus \u00e9quitable quo.\n\nN'ayez crainte, \u00e0 vos cosses, in vos manque point d'invi\u00e9es,\nAvec nous, v'lo ch\u00e9 d\u00e9sirs qui s'ront r\u00e9alis\u00e9s,\nAinse picotich' par picotiche, vos' aude s\u00fbr'mint brouille,\nEt in avin\u00e7ant dins c'combat, long-auln\u00e9e nones flue.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 ceux qui luttent pour un revenu de base universel, r\u00eavant d'une soci\u00e9t\u00e9 \u00e9quitable.",
            "critics": ""
        },
        {
            "poem": "Un jour, au tchamp, l'travailleu s'est dit,\nQui n'frot pus brait, alor i s'est r\u00e9cri,\nConte \u00e8ch recul de l'r'traite i fo s'batte,\nMaronfe aimpr\u00e9, n'volo nou trachte!\n\nSus la calva fort ergot ich'a \u00e9t\u00e9,\nAdon, ch\u00e9s gins fionten, in haut clam\u00e9,\nCh'est tchiau'pour nou, l'rte avanr robonchant,\n\u00c8ch t'baileur d'ains, qu'\u00e0 tout hardjaid ch\u00e9 viers t'enchante!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur le combat des travailleurs contre le recul de l'\u00e2ge de la retraite.",
            "critics": ""
        },
        {
            "poem": "Yo, \u00e9coutez bien la chanson d'un vrai Picard,\nC\u00e9l\u00e9brant la diversit\u00e9, sans \u00eatre cafard,\nLes st\u00e9r\u00e9otypes c'est foireux, on les bris\u00e9,\nOn s\u00e8me les graines de l'\u00e9galit\u00e9, t'as vu l'id\u00e9e ?\n\nLes femmes battent de l'aile, les hommes portent l'b\u00e9tchot,\nChacun est libre d'\u00eatre soi-m\u00eame, sans s'occuper des marmots,\nRejoins-nous, soutiens cette cause tout en \u00e9tant picard,\nTous ensemble, on fait taire les bavards, on va d'l'avant sans cafard.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap c\u00e9l\u00e9brant la diversit\u00e9 des genres et l'importance de briser les st\u00e9r\u00e9otypes.",
            "critics": ""
        },
        {
            "poem": "Yo, t'chaud dins ch'jargon du Picard,\nAchteure j'rappe pou d'nicher vos lichards,\nPoliticiens sourts, aveugles \u00e0 no' bataille,\nD\u00e9ganant, fiant-nos truche \u00e0 l'mireuch n'calle,\n\nI parait qu'vo' \u00e9crivez ch'l'histoar?\nMais ch'est nous, ch'peule, qu'vot iss' ich, ouais ch't'arbargne,\nAussons les haines, les tracas d'o\u00f9qu'on vient,\nRindonos ch'pouvoir et v'la qu'nos vins se lient!",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track moquant les politiciens qui ignorent les revendications des manifestants.",
            "critics": ""
        },
        {
            "poem": "Militants d'\u00e9galit\u00e9, berc\u00e9s d'esp\u00e9rance,\nMi j'vouloais raconter vos combats pour les f\u00e9m'nes,\n\u00c0 travers ch\u00e9s \u00e9turdi\u00e9s d'chi motte entre nos mains,\nVous n'fait'k\u00e9 d'chuquer, mille foiel\u00e9es, l'dzouvie.\n\nJ'apr\u00e9sie vote t\u00e9nacit\u00e9 afin qu'les fions,\nCh\u00e9s guernouales pi ch\u00e9s gas, ch\u00e9s gins tous bernes,\nVivent dins l'arconnaiss'ance, l'amour pi la chimde\nIn vie picarde, main dins l'c\u0153ur, fort d\u00e9 kiarts.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux militants f\u00e9ministes, remerciant leur engagement pour l'\u00e9galit\u00e9 des sexes.",
            "critics": ""
        },
        {
            "poem": "Insi\u00e8me, dins l'fosse et l'usine, on s'bat,\nCamarades, ch\u00e9s fr\u00e9ros quo rassempl\u00e9s,\nEhont l'coeur groumant d'espoir, es-caudronnat,\nAtravers l'brume du patois picard r\u00e9sonne.\n\nL\u00e9ve no z'amis vers eune mund' pus grand,\nO\u00f9 ch\u00e9s prol\u00e9taires s'unissent \u00e9dmain,\nAveuc' feurme \u00e9d vir \u00e8v'noir l'preux destain,\nMarcher als' r\u00e9volut', fainds la haine, corroborant.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la force collective des prol\u00e9taires unis pour une r\u00e9volution sociale.",
            "critics": ""
        },
        {
            "poem": "Aux barri\u00e8res du tumulte, dins l'or\u00e9e d'ch\u00e9 Retraits,\nOn s'bouche \u00e0 fiot d'bourriache, en l'ivalant fo\u00fbt d'eu.\nIl est temps d\u00e9 s'elver, conpleter ch\u00e9s batailles,\nGarder no gagne-pain, potard su no\u00fb route trabouler.\n\nI faule agir dallache, aver l'amboche palam\u00eale,\nBuster ch\u00e9s lois squ\u00e9leutiques, gar\u00f4ch\u00e9es au tarabiclot.\nPoute eune main \u00e0 ch'outi pour nos r\u00e9vo\u00fbs advenir,\nCh'cor d'no\u00fb droits, ch'ciel d'no\u00fb \u00e2ge d'or, vo\u00fb piaute n'a point d'fin.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap d\u00e9crivant la lutte pour la protection du droit \u00e0 la retraite, insistant sur l'urgence d'agir.",
            "critics": ""
        },
        {
            "poem": "Ch\u00e9s ringards du capitalisme, in vot' manoir\nA peuffiez su' ch\u00e9s mis\u00e9rab' pas de hasard\nI-feum'tent d'fond' ch\u00e9s ouvriers comme Pi\u00e8tre\nAvec leu' fichu syst\u00e8m', ale est am\u00e8re\n\nCh\u00e9s gouvern'mints, sans \u00e9tot clafi d'beloche\nI' coeu'tent l'quemin d'la deshinse, sans coeurtjoche\nSus \u00e0 l'guern\u00e8stchi, l'tyran au mille fayayes\nQu'm\u00e9rite qu'd'aller chif'fer au fond d'el' blayatte",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track critiquant l'oppression des classes ouvri\u00e8res par le capitalisme.",
            "critics": ""
        },
        {
            "poem": "Amo\u00fbr \u00e0 fraterie, br\u00e2ve chin d'combad,\nSus ch'champ d'gu\u00e8rre, insinm' cont' l'castad;\nTravailleus, minorit\u00e9s, avot marliv\u00e9s,\nIn patois picard, vos d\u00e9sirs s'r\u00e9villaient.\n\nAvo 's'arms', ch\u00e9s droits pi d'valeus afuqu\u00e9s,\nMains d'su l'tchu\u00e9r', pr\u00e9s d'avainche pi d'luch\u00e9;\nQ'\u00ef ch'burette, gachtou b\u00e9tot r'cress\u00e8re,\nDi l'union s'\u00e9lv'rat \u00e8fri ch\u00e9s fr\u00e8res.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 la fraternit\u00e9 entre les luttes pour les droits des minorit\u00e9s et les droits des travailleurs.",
            "critics": ""
        },
        {
            "poem": "Quand j'\u00e9tot l'lonne su min pieu\nJ'arpentot l'flore ches cordieux\nNi homme, ni femme, ces gins beaux,\nAstheure cho, cher mi, ch'est l'r\u00f4.\n\nJ'cache nin de m\u00e9pris \u00e0 prinde\nAmour, amitieusse, peu importe l'binde\nDans min coeur fi\u00e8r ch'est tot clair\nEt j'marche aupr\u00e8s picard fier.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la fiert\u00e9 d'\u00eatre soi, ind\u00e9pendamment de son genre ou orientation sexuelle.",
            "critics": ""
        },
        {
            "poem": "J'vas vous raconter un truc qu'cha fait mal, in patois picard\nDes moquets, des coups d'baton, l\u00e9 fames subissent ch\u00e9s farces\nMais in r'monte l'cal\u00e8che, pas b'soin d'vos mopets et vos escardes\nCh'f\u00e9minisme ch'est \u00e0 ch\u00e9s filles ardennes, pas seulem'n' \u00e0 L\u00e9onard\n\nOn n'veut pu vos tracas, vos piq\u00fbres, fini ch'cafard\nIn s'bat pour nos droits d'infant, m\u00e8re, fille, soeur, faites z\u00e9zard\nV'nir \u00e0 bat' ch\u00e9s violences, au nom d'amour ch'est un canard\nFaut s'serrer les mains, unies, et dire adieu au galvaudard",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap d\u00e9non\u00e7ant les violences faites aux femmes et l'importance du f\u00e9minisme.",
            "critics": ""
        },
        {
            "poem": "Yo, \u00e9coutez bien, j'pr\u00e9pare un tir,\nContre la discrimination, j'vais pas mentir,\nDans l'monde du travail, faut qu'\u00e7a s'am\u00e9liore,\nPour les LGBTQ+, on veut \u00eat' acteur.\n\nLes Picards dans c't'gal\u00e8re, aussi opprim\u00e9s,\nDiscrimin\u00e9s, kinkin, faut l'admett' fort,\nOn doit \u00eat' solidaires, ouvrez vos cervelles,\nCh\u00e9s LGBTQ+, y z'ont leur plache parel.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre la discrimination envers les personnes LGBTQ+ dans le monde du travail.",
            "critics": ""
        },
        {
            "poem": "A vous, c\u0153urs vaillants qu'charchez \u00e0 r\u00e9nouvler,\nIn traval d'nsemb' pour ainch\u00e9 tout yimbler,\nDins vo combat, O picard ch'queuf nourris',\nLa bellaffair' por in monde \u00e9gal'tai.\n\nGochmas sus tertous, vous lutt'ez sans parlote,\nBrajant l'obstacles avec in baudet fort,\nVos m'nisses' b\u00e9nif's pour achoir d'ardon\nAinosti qu'vo garchons 'semble avanc'nos.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 ceux qui luttent pour un monde plus inclusif et \u00e9galitaire.",
            "critics": ""
        },
        {
            "poem": "Insiem' fr\u00e9ros, main dins l'main, \u00e9mon\u00e7'te les prumioles\nIn brayant \u00e9mass\u00e9s, nos c\u0153urs furt\u00e9chiots,\nCh'est bien not' vogue, qu'bruyit dins s\u00e9z' arrioles\nEs' cande, en'll' courache, el' force des tiots.\n\nQuo' nin dur, ch'paysan d'estoge, m'\u00e9coute !\nFaut mantener l'ouvrage chu l'sanmel\u00e9 voixux.\nS'rin solidaires et finquer nos botes\nPour construire en demain, \u00e0 l'abri des vintiaux.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la solidarit\u00e9 entre les manifestants pour un avenir meilleur.",
            "critics": ""
        },
        {
            "poem": "Yo, dans ch\u2019monde, i a trop d'precarit\u00e9\nI faut mette in place un revenu, ch'est d'actualit\u00e9\nUn revenu d'base universel, ch'moyin d'avencer\nPour r\u00e9duire et pr\u00e9venir, tout c'peu sbagommer\n\nAvec ch'RBW, pr\u00eate mine \u00e0 ch\u00e9 gins dans ch'nord\nI vont ramasser l'fromache comme un vrai tch\u00f4rd\nAinsi, i seront plus foqueniaux, pis d'gueule\nUnie in ch'combat pour chin tchot catiau au seuil",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur la n\u00e9cessit\u00e9 de mettre en place un revenu de base universel pour r\u00e9duire la pr\u00e9carit\u00e9.",
            "critics": ""
        },
        {
            "poem": "Yo, ch\u00e9s politiques, t\u00e9 vaudrot qu'rout d'dins ch'mat\nL\u00e0 d'dins vos palach', ch'est vrai, t'\u00eate bien gras\nT\u00e9 s'occup' point d'ch\u00e9s padrins qu'descinde el marmite\nIn\u00e9galit\u00e9s qu'grossisse, et nou qu'\u00eatro l'plu vite\n\nFaudrot qu'vous \u00eatro actifs face \u00e0 ch\u00e9s gapiaux\nMi, si j't'uche \u00e0 ch'bouterbio, j'vous m\u00e8te su tandio\nInjustice, ch'est ein fl\u00e9au, mais t\u00e9 n'fait point d'chose\nEune darnation d'si\u00e9tle et t'\u00eate toudis d'vos belote closse",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre l'inaction politique face aux in\u00e9galit\u00e9s sociales et \u00e9conomiques.",
            "critics": ""
        },
        {
            "poem": "Mes chers activistes, mi amours si forts,\n\u00c0 vous qu'bravez leu n\u00e9ant pour l'\u00e9galit\u00e9,\nIn votr' ciel \u00e9clairant vos ripat'>\nPour un monde miux et plus soft.\n\n\u00c0 vous les rudes batay'ux d'la bont\u00e9,\n\u00d4 combien je vous adore et vous honnore,\nChaque geste de vot' mi', chaque pens\u00e9',\nEngag\u00e9' \u00e0 nos vies, chi\u00e8re et tendw'>\n\nNote: \"ri\">\" est un mot picard qui signifie \"ressemblance\" et \"tendw'>\" signifie \"ch\u00e9ri\".",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux activistes qui \u0153uvrent pour un monde plus juste et \u00e9quitable.",
            "critics": ""
        },
        {
            "poem": "Dins ch\u00e9s lutes, ch\u00e9s r\u00e8ches in diverse,\nChaqueun vir, cache marone un flambo.\nPi\u00e9jorins tous unis, pour un monde pus biau,\nQuind s'ches droits humains n'fuchent pus l'bers\u00e9e.\n\nEu l'horillon d'un aveunir miux jonnin,\nInsemble, \u00e0 coeur vaillant, on d\u00e9ly\u00e8r'rin;\nArtrouverons, tout fo\u00e8re, in R\u00e9publicue Pichonne,\nEt dins l'esp\u00e9rance, ch\u00e9s lumes s'piqueront.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me c\u00e9l\u00e9brant la diversit\u00e9 des luttes pour les droits humains, unies pour un avenir meilleur.",
            "critics": ""
        }
    ],

    "fr": [
        {
            "poem": "Ainsi l'in\u00e9galit\u00e9 en cette soci\u00e9t\u00e9,\nBlessure des femmes cherchant \u00e0 travailler,\nCruel stigmate des genres, mal endur\u00e9,\nD\u2019une lourde histoire, trop longtemps murmur\u00e9e.\n\nAu sein des bureaux et des usines, scind\u00e9e,\nBataille doit cesser, pour les femmes l\u00e9s\u00e9es,\nChasser les pr\u00e9jug\u00e9s du pass\u00e9, oubli\u00e9s,\nD'un monde nouveau, s'unir et s'\u00e9panouir.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me d\u00e9non\u00e7ant l'in\u00e9galit\u00e9 des genres dans le monde du travail, les st\u00e9r\u00e9otypes persistants.",
            "critics": ""
        },
        {
            "poem": "A bas les patrons, les requins de l'monde moderne,\nB\u00e2tissons ensemble une soci\u00e9t\u00e9 moins barbare,\nCombattant pour nos droits, ensemble en premi\u00e8re ligne,\nD\u00e9cha\u00eenant la r\u00e9volution, nos voix qui concordent.\n\nAux barricades, camarades ! Lutte pour la justice,\nBrisons les cha\u00eenes de l'oppression, soyons progressistes,\nCroyez en notre force, montrons-leur nos convictions,\nDroit vers la victoire, chassons l'exploitation.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap critiquant les entreprises exploitant les prol\u00e9taires, appelant \u00e0 la r\u00e9volution ouvri\u00e8re.",
            "critics": ""
        },
        {
            "poem": "Avec Macron au pouvoir, la retraite fout le camp\nBoulot jusqu'\u00e0 la mort, notre avenir est flippant\n\u00c7a fait longtemps qu'on rame, sans espoir d'une pause\nDerni\u00e8re ligne droite qu'on va finir en moignon\n\nAuge du capitalisme, c'est notre dos qu'on casse\nBriser nos espoirs et tout ce pour quoi on trime\n\u00c7a d\u00e9goute les masses, on n'aura rien d\u00fb donner\nD\u00e9termin\u00e9s, ensemble pour combattre sa folie",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre Macron pour sa politique de retraite, d\u00e9crivant les cons\u00e9quences sur les travailleurs.",
            "critics": ""
        },
        {
            "poem": "Amour de la lutte, main dans la main,\nB\u00e2tissons ensemble nos lendemains,\nCompagnons unis, c\u0153ur solidaire,\nDans notre qu\u00eate d'un monde plus prosp\u00e8re.\n\nEnsemble par la force de notre passion,\nFaisons vibrer nos c\u0153urs, une seule raison,\nGr\u00e2ce \u00e0 notre pouvoir, nous briserons les cha\u00eenes,\nH\u00e9ritons d'un futur d'amour qui nous entra\u00eene.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 la solidarit\u00e9 entre les manifestants, c\u00e9l\u00e9brant leur unit\u00e9 pour un futur meilleur.",
            "critics": ""
        },
        {
            "poem": "Amour, il est temps de se r\u00e9veiller,\nBriller, toutes les couleurs, toutes les formes,\nChacun a le droit \u00e0 leur place au soleil,\nDans ce monde, acceptons nos d\u00e9sirs.\n\nApprenons \u00e0 aimer sans fronti\u00e8res,\nBr\u00fblons les normes, l'amour ne dicte pas,\nC\u00e9l\u00e9brons, rions, partageons ensemble,\nD\u00e9couvrons cette diversit\u00e9 qui nous enchante.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur l'importance de respecter toutes les orientations sexuelles, appelant \u00e0 l'acceptation et l'amour.",
            "critics": ""
        },
        {
            "poem": "A l'\u00e9poque o\u00f9 la lutte a commenc\u00e9,\nBatailles pour l'\u00e9galit\u00e9, les femmes ont avanc\u00e9,\nChaque jour, des victoires et des d\u00e9fis \u00e0 relever,\nD\u00e9molissant les st\u00e9r\u00e9otypes, sans jamais reculer,\n\nAdmirez ces guerri\u00e8res, d\u00e9termin\u00e9es et fortes,\nBravant les obstacles, elles ont ouvert des portes,\nCombattant pour leurs droits, elles inspirent encore,\nD\u00e9livrant un message d'espoir, \u00e0 jamais elles transportent.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur la lutte des femmes pour l'\u00e9galit\u00e9, soulignant leurs victoires et d\u00e9fis dans le f\u00e9minisme.",
            "critics": ""
        },
        {
            "poem": "A l'\u00e9poque o\u00f9 les riches s'enrichissent, le peuple s'affaiblit\nB\u00e2tir un monde juste on oublie, trop de personnes en conflit\nC'est inadmissible qu'un revenu de base universel manque ici\nD\u00e9guster le go\u00fbt amer d'une soci\u00e9t\u00e9 o\u00f9 la pr\u00e9carit\u00e9 s'\u00e9panouit \n\nA l'aube d'un monde qui change, y a-t-il vraiment de quoi \u00eatre fier?\nBriser les cha\u00eenes de l'oisivet\u00e9, il est temps de se r\u00e9veiller \nCroyez-vous que vivre sans un revenu de base soit \u00e9quitable?\nD'autres alternatives sont possibles, faut l'\u00e9crire sur la table",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track critiquant la pr\u00e9carit\u00e9 caus\u00e9e par le manque de revenu de base universel.",
            "critics": ""
        },
        {
            "poem": "Amis des droits du travailleur, vous m\u00e9ritez l'amour,\nBrillants d\u00e9fenseurs, vous \u00eates un tr\u00e9sor.\nChaque jour, pour la retraite, vous luttez sans rel\u00e2che,\nDignes d'\u00e9loges, sinc\u00e8re est ma d\u00e9marche.\n\nAidants de tant de vies, merci pour votre action,\nBerceau de justice, re\u00e7ois ma d\u00e9votion.\nCourageux soldats, toujours droit dans vos bottes,\nDoux h\u00e9ros anonymes, acceptez mes cocottes.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux d\u00e9fenseurs du droit \u00e0 la retraite, exprimant gratitude et soutien.",
            "critics": ""
        },
        {
            "poem": "Aux anciens temps les prol\u00e9taires, soumis et vigilants nous f\u00fbmes,\nBrillant est le jour o\u00f9 pour la justice, la r\u00e9volte des opprim\u00e9s s'allume,\nContre les cha\u00eenes des puissants, notre lutte se dresse, imp\u00e9rieuse et crue,\nDans l'espoir d'un monde plus juste, notre \u00e2me ne cessera jamais de se battre, \u00e9perdue.\n\nAvec une ardeur d\u00e9cha\u00een\u00e9e, nos c\u0153urs flamboient pour la justice,\nBrisant nos liens, vers un avenir plus radieux et empli de promesses,\nCar la r\u00e9volution des prol\u00e9taires engendre, dans sa lutte effr\u00e9n\u00e9e,\nDes espoirs d'\u00e9galit\u00e9, pour un monde \u00e9pris de libert\u00e9 et de fraternit\u00e9.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me exaltant les espoirs d'un monde plus juste gr\u00e2ce \u00e0 la r\u00e9volution des prol\u00e9taires.",
            "critics": ""
        },
        {
            "poem": "A travers la pluie et l'orage, on se bat pour nos droits\nBrisant les murs, les barri\u00e8res, on ne c\u00e9dera pas\nC'est le moment de montrer notre fiert\u00e9, tol\u00e9rance et amour\nDans la rue avec courage, ensemble pour toujours\n\nAffrontant les haters, on demeure solidaires\nB\u00e2tissant un futur \u00e9gal, d'intol\u00e9rance on se lib\u00e8re\nC\u00e9l\u00e9brons notre diversit\u00e9, notre force, notre union\nDroit de vivre nos vies, sans honte et sans question",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur les manifestations pour les droits LGBTQ+, soulignant leur courage et pers\u00e9v\u00e9rance.",
            "critics": ""
        },
        {
            "poem": "A l'aube de ce monde cruel o\u00f9 l'injustice r\u00e8gne\nB\u00e2tards de l'argent, obs\u00e9d\u00e9s par leur gain\nCrient les pauvres, \u00e9cras\u00e9s par votre \u00e9go\u00efsme\nD\u00e9s\u00e9quilibr\u00e9, l'\u00e9cart de richesse est un ab\u00eeme\n\nAveugles face aux mis\u00e8res, aux souffrances endur\u00e9es\nBris\u00e9es les vies, les r\u00eaves dans votre tour dor\u00e9e\nChacun m\u00e9rite mieux que ces conditions impos\u00e9es\nD\u00e9racin\u00e9s, nous devons unir nos forces pour tout renverser",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track d\u00e9non\u00e7ant l'injustice \u00e9conomique et l'\u00e9cart grandissant entre riches et pauvres.",
            "critics": ""
        },
        {
            "poem": "Amoureux du progr\u00e8s, champions d'\u00e9quit\u00e9,\nB\u00e2tisseurs de revendication au fil de la soci\u00e9t\u00e9,\nCr\u00e9ateurs de d\u00e9mocratie et de sagesse endurcie,\nD\u00e9fenseurs d'un futur o\u00f9 r\u00e8gne la justice pour tous,\nEsp\u00e9rant un revenu de base pour l'\u00e9mancipation,\nFervents militants pour un monde sans in\u00e9galit\u00e9s,\nG\u00e9n\u00e9reux et passionn\u00e9s, luttant pour la fraternit\u00e9,\nHonorez cette lutte, d\u00e9termin\u00e9s \u00e0 l'iniquit\u00e9 subjuguer.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 ceux qui luttent pour un revenu de base universel, r\u00eavant d'une soci\u00e9t\u00e9 \u00e9quitable.",
            "critics": ""
        },
        {
            "poem": "Autour de nous, les travailleurs luttent\nBravant les lois pour une retraite juste\nContre les maux du temps, ils s'ent\u00eatent\nDans le combat, ils demeurent robustes\n\nEmportant avec eux leurs r\u00eaves d'antan\nFiers et courageux, ils ne c\u00e9deront pas\nGagnant enfin la bataille en payant\nHonn\u00eate tribut au combat des progr\u00e8s",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur le combat des travailleurs contre le recul de l'\u00e2ge de la retraite.",
            "critics": ""
        },
        {
            "poem": "A tous les genres et toutes les couleurs, unissons-nous en ch\u0153ur\nBrisons les barri\u00e8res, les st\u00e9r\u00e9otypes, on en a pas peur\nChoisis ta voie, ta libert\u00e9, ton bonheur est l'essence\nDiversit\u00e9, c'est beau, c'est fort, c'est notre puissance\n\nAucune diff\u00e9rence, juste l'amour et la tol\u00e9rance\nBannissons le jugement, soyons tous d'une seule alliance\nConstruire un monde o\u00f9 chacun trouve sa place et s'\u00e9panouit\nD\u00e9passons le niveau de la simple apparence : voil\u00e0 la vraie vie",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap c\u00e9l\u00e9brant la diversit\u00e9 des genres et l'importance de briser les st\u00e9r\u00e9otypes.",
            "critics": ""
        },
        {
            "poem": "A tous ces politiciens, trop sourds pour nous entendre\nBrouill\u00e9s dans leur bulle, croyez-vous qu'on va se taire\nCessez de faire la sourde oreille, on veut du changement\nDans nos rues, nos voix s'\u00e9l\u00e8vent, face \u00e0 l'indiff\u00e9rence\n\nAllez, encore une fois, on r\u00e9p\u00e8te nos revendications\nBorn\u00e9s, aveugl\u00e9s, \u00e7a suffit, il est temps d'\u00e9couter\nCe combat que l'on m\u00e8ne, c'est pour un avenir meilleur\nDonnez-nous un peu d'air, laissez-nous respirer ce bonheur",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track moquant les politiciens qui ignorent les revendications des manifestants.",
            "critics": ""
        },
        {
            "poem": "Amour pour les militantes d\u00e9vou\u00e9es,\nBataillant pour l'\u00e9galit\u00e9 chaque journ\u00e9e,\nComme les guerri\u00e8res aux grands c\u0153urs,\nD\u00e9vou\u00e9es pour briser les st\u00e9r\u00e9otypes de peur,\n\nAbolir les pr\u00e9jug\u00e9s contraires \u00e0 l'amour,\nB\u00e2tir un monde meilleur, main dans la main, pour toujours,\nChercher l'\u00e9quilibre et l'\u00e9quit\u00e9 dans un monde superbe,\nDouce main tendue pour un monde plus libre.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux militants f\u00e9ministes, remerciant leur engagement pour l'\u00e9galit\u00e9 des sexes.",
            "critics": ""
        },
        {
            "poem": "Ainsi les prol\u00e9taires unis trouveront force,\nBrisant les cha\u00eenes, balayant l'injustice,\nContre vents et mar\u00e9es, luttant sans rel\u00e2che,\nDans l'espoir d'un monde d\u00e9barrass\u00e9 de la t\u00e2che.\n\nA nouveau, les travailleurs montreront leur fiert\u00e9,\nB\u00e2tissant une soci\u00e9t\u00e9 o\u00f9 tous seront respect\u00e9s.\nCombattre, r\u00e9sister, ensemble sans fin,\nDans la qu\u00eate d'un futur meilleur, main dans la main.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la force collective des prol\u00e9taires unis pour une r\u00e9volution sociale.",
            "critics": ""
        },
        {
            "poem": "A - Attends, le temps presse, la retraite menace, dis donc,\nB - Battre le pav\u00e9, c'est c'qu'on fait, avec-vous mon\u00e7ons,\nC - Compter sur les jeunes pour prot\u00e9ger les anciens,\nD - D\u00e9fendre notre droit pour un avenir certain.\n\nA - Agissons, ensemble, pour chaque g\u00e9n\u00e9ration,\nB - Bons moments \u00e0 pr\u00e9server, notre motivation,\nC - Ce combat, pour la dignit\u00e9, nous unissons,\nD - Donnant notre voix pour un futur en fusion.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap d\u00e9crivant la lutte pour la protection du droit \u00e0 la retraite, insistant sur l'urgence d'agir.",
            "critics": ""
        },
        {
            "poem": "A l'aube, c'est la mis\u00e8re et la crise qui nous rongent\nB\u00e2tissant nos vies sur des mensonges\nCapitalistes avares, sceau de notre malheur\nD\u00e9truisant nos r\u00eaves dans leur brume de lueur\n\nA genoux, ouvrier, c'est ta force qu'ils prennent\nBroie leurs syst\u00e8mes creux, ne laisse pas qu'ils gagnent\nCar les oppressions menacent dans les ombres\nD\u00e9molis les cha\u00eenes qui nous plongent dans les d\u00e9combres",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track critiquant l'oppression des classes ouvri\u00e8res par le capitalisme.",
            "critics": ""
        },
        {
            "poem": "Amour entre fr\u00e8res, luttes solidaires,\nBrisons les cha\u00eenes, pour un monde plus clair,\nCh\u00e9rissons nos causes, pour les droits des travailleurs,\nDignit\u00e9 et respect, toujours batteurs.\n\nAu sein des minorit\u00e9s, luttons sans fin,\nBas les oppressions, main dans la main,\nCroyons en l'\u00e9galit\u00e9 et la justice sociale,\nD\u00e9fendons nos id\u00e9aux, ensemble, sans faille.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 la fraternit\u00e9 entre les luttes pour les droits des minorit\u00e9s et les droits des travailleurs.",
            "critics": ""
        },
        {
            "poem": "Aime-toi tel que tu es, sans crainte ni peur,\nBrille de mille feux dans ton amour si pur,\nCar qu'importe ton genre ou ton orientation,\nDe ta valeur, il n'y est rien moins que l'\u00e9manation.\n\nEntre les lignes de l'arc-en-ciel qui te dessine,\nFi\u00e8re et sereine, ne laisse personne d\u00e9valiser ton estime,\nGlorieux sois en ton essence, loin des jugements artificiels,\nHonore le coeur qui est le tien, sois ton propre ciel.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la fiert\u00e9 d'\u00eatre soi, ind\u00e9pendamment de son genre ou orientation sexuelle.",
            "critics": ""
        },
        {
            "poem": "A l'abri ne sont pas nos s\u0153urs, nos amies\nBattues, viol\u00e9es, elles subissent l'envie\nContre leur volont\u00e9, elles crient dans la nuit\nD\u00e9non\u00e7ons ces actes, le combat poursuit\n\nA quoi bon la force, sert-elle \u00e0 prouver?\nBravons notre \u00e9go, faut-il respecter\nChaque femme vit, laissez-la s'exprimer\nD\u00e9fendons leur vie, ensemble, \u00e9galit\u00e9",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap d\u00e9non\u00e7ant les violences faites aux femmes et l'importance du f\u00e9minisme.",
            "critics": ""
        },
        {
            "poem": "A l'aise dans nos boulots, on m\u00e9rite d'y \u00eatre\nBrisons les st\u00e9r\u00e9otypes pour qu'ils nous laissent para\u00eetre\nCruaut\u00e9 et haine, il n'y a pas de place ici\nDiscrimination, c'est inutile, vieille ranc\u0153ur de l'ennui\n\nA nouveau, pr\u00e9jug\u00e9s, on les affronte sans cesse\nB\u00e2tissons l'avenir, les comp\u00e9tences qu'on d\u00e9laisse\nCes diff\u00e9rences, c'est l'humanit\u00e9 en vitalit\u00e9\nD\u00e9cha\u00een\u00e9s comme un torrent, notre unit\u00e9 va rien l\u00e2cher",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre la discrimination envers les personnes LGBTQ+ dans le monde du travail.",
            "critics": ""
        },
        {
            "poem": "Amour est la force qui guide ces coeurs vaillants,\nBravant les injustices, unissons nos talents,\nConstruisons ensemble un monde meilleurs et puissant,\nD\u00e9di\u00e9 aux valeurs d'\u00e9galit\u00e9 et d'entraide ardente.\n\nAmis qui luttez, sachez que nous sommes \u00e0 vos c\u00f4t\u00e9s,\nBandissons les diff\u00e9rences, l'ignorance, in\u00e9galit\u00e9s,\nCh\u00e9rissons la diversit\u00e9, dans ce combat acharn\u00e9,\nDe l'inclusion et la justice, tous ensemble \u00e9pris en r\u00e9alit\u00e9.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour \u00e0 ceux qui luttent pour un monde plus inclusif et \u00e9galitaire.",
            "critics": ""
        },
        {
            "poem": "A travers les rues nous marchons tous unis\nB\u00e2tissant ensemble un futur \u00e9clair\u00e9\nChacun apportant sa force et son envie\nD\u00e9fi commun, un monde \u00e0 transformer\n\nA chaque pas, nous sommes solidaires\nBrisant les cha\u00eenes et d\u00e9fiant l'adversit\u00e9\nCourageux, nous luttons pour un avenir prosp\u00e8re\nD\u00e9termin\u00e9s et unis, main dans la main, pour la libert\u00e9",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me sur la solidarit\u00e9 entre les manifestants pour un avenir meilleur.",
            "critics": ""
        },
        {
            "poem": "A chaque jour, la gal\u00e8re, la pr\u00e9carit\u00e9 qu'on traverse\nBriser les cha\u00eenes, le revenu de base universel on observe\nC'est le moment de venir soutenir cette cause sans tr\u00eave\nD'autres avant nous ont lutt\u00e9 pour nos droits et la paix\n\nA l'horizon, un avenir meilleur, unissons-nous pour un id\u00e9al\nBattre la pauvret\u00e9, luttons ensemble pour un monde \u00e9gal\nCroire en nos forces, pour un revenu de base accessible\nDemeurons vigilants, car la justice sociale est notre cible",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un rap sur la n\u00e9cessit\u00e9 de mettre en place un revenu de base universel pour r\u00e9duire la pr\u00e9carit\u00e9.",
            "critics": ""
        },
        {
            "poem": "A l'\u00e9cart du peuple, planqu\u00e9s dans leurs palais\nBourgeois politiciens nous oublient sans regret\nCravat\u00e9s et gras, ces \u00e9lus s'en moquent\nDes in\u00e9galit\u00e9s, des pauvres qui s'achoppent\n\nA nouveau les taxes, ils les hausseront\nBarrant l'acc\u00e8s aux soins, aux pensions\nCombattre le syst\u00e8me, on doit sans rel\u00e2che\nD\u00e9verser nos cris jusqu'\u00e0 qu'ils nous arrachent.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un diss track contre l'inaction politique face aux in\u00e9galit\u00e9s sociales et \u00e9conomiques.",
            "critics": ""
        },
        {
            "poem": "Amour de la justice, vous brillez tels des astres,\nBravant les injustices, combattant sans d\u00e9sastre,\nCr\u00e9ant un monde meilleur par votre d\u00e9votion,\nD\u00e9vou\u00e9s corps et \u00e2me \u00e0 chaque situation.\n\nAinsi vous nous guidez, lumi\u00e8re d'un id\u00e9al,\nBelles \u00e2mes, vous unissez vos forces sans rival,\nChaque jour, votre courage d\u00e9fend notre humanit\u00e9,\nDivins activistes, en r\u00e9ussite, demeurent dignit\u00e9.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Une lettre d'amour aux activistes qui \u0153uvrent pour un monde plus juste et \u00e9quitable.",
            "critics": ""
        },
        {
            "poem": "Ardentes les lueurs des combats pass\u00e9s,\nBanni\u00e8res multicolores pour droits \u00e9treints,\nChantons tous ces h\u00e9ros trop souvent effac\u00e9s,\nDans l'ombre et la lumi\u00e8re unis pour un refrain.\n\n\u00c9clats de libert\u00e9, feu sacr\u00e9 qui enflamme,\nFiers et r\u00e9solus, debout pour l'\u00e9galit\u00e9,\nGlorifions nos luttes, c\u00e9l\u00e9brons notre trame,\nHumanit\u00e9 unie, vers un avenir dor\u00e9.",
            "topic": "",
            "acrosticWord": "",
            "acrosticLine": "A to D",
            "language": "en",
            "poemSize": 8,
            "model": "gpt4",
            "title": "Un po\u00e8me c\u00e9l\u00e9brant la diversit\u00e9 des luttes pour les droits humains, unies pour un avenir meilleur.",
            "critics": ""
        }
    ],

    'it': [{
        'poem': "Attraverso secoli e confini\nBattaglie di donne e sorelle\nCon coraggio e dedizione sfidano\nDestini opposti, vite che si ribellano\n\nAlzando la voce per lottare\nBrillano come stelle in questa guerra \nContro l'oscurità, l'ingiustizia\nDifendono la dignità di ogni pietra",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una poesia sulla lotta delle donne per l'uguaglianza di genere e contro la violenza.",
        'critics': ''
    },
    {
        'poem': "Amore è rispetto, un diritto sacrosanto\nBisogna imparare, a non fare uno sbaglio\nCelebriamo l'amor, senza barriere o muri\nDiversità è gioia per vite maturi\n\nAmore non ha limiti con l'identità\nBruciando pregiudizi, è la nostra onestà\nCon orgoglio e rispetto, ogni cuore s'infiamma\nDonando libertà, celebra ogni anima",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una canzone sull'importanza del rispetto dell'identità di genere e dell'orientamento sessuale.",
        'critics': ''
    },
    {
        'poem': "A scuola tutti insieme, ci avviamo con il sole,\nBasta con le divisioni, sì all'istruzione per tutte le persone,\nColtiviamo la mente, cresciamo ogni giorno,\nDiamoci una mano, l'istruzione è il nostro piano.\n\nAlziamo lo sguardo, il futuro ci aspetta,\nBattiamoci per l'educazione, non c'è sconfitta,\nCon la cultura, facciamo la differenza,\nDi fronte all'ignoranza, l'istruzione è la resistenza.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Un rap sull'importanza dell'istruzione pubblica e gratuita.",
        'critics': ''
    },
    {
        'poem': "Amore mio, sento il tuo dolore,\nBramo far sparir l'ignoranza da ogni cuore,\nCullarti dolcemente, or che sei triste,\nDifenderti e amarti, su me la sfida è pronta.\n\nAncora insieme, supereremo gli ostacoli,\nBianca colomba, volerai sopra i pregiudizi,\nCreeremo un mondo migliore e sensibile,\nDimmi tutto, insieme, facciam volar l'amore.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una lettera d'amore per un partner che ha subito discriminazioni sul posto di lavoro a causa della sua identità di genere.",
        'critics': ''
    },
    {
        'poem': "Ardite e fieri, guerriere del nuovo,\nBattaglie di giustizia combattiamo,\nContro il nemico oppressor, forte riamo,\nDonne unite, il patriarca sgretoliamo.\n\nAncor più forti, sotto il ciel stellato,\nBattendo cuori liberi di speranza,\nCon fiamme ardenti nel cuor, la saggezza,\nDall'antica armonia rinasciam danza.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una poesia sulla forza delle donne nella lotta contro il patriarcato.',
        'critics': ''
    },
    {
        'poem': "Amici uniti, combattiamo il male\nBasta discriminazione, suona il segnale\nContro l'ignoranza, la disabilità\nDiamo importanza all'uguaglianza, oh-yeah\n\nAbbracciamo la diversità, col cuore aperto\nBasta pregiudizi, spazziamoli via, è giusto\nCol nostro amore, ognuno è speciale\nDisabilità e differenze, tutte da celebrare",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una canzone sull'importanza di combattere la discriminazione nei confronti delle persone disabili.",
        'critics': ''
    },
    {
        'poem': "Ama il pianeta, tienilo pulito\nBella natura, guida il tuo destino\nCustodisci l'aria, l'acqua e la terra\nDimostra al mondo quanto ti dispera\n\nAlberi e foreste, vitali per la vita\nBiodiversità, il nostro tesoro e guida\nContinua nella lotta contro il riscaldamento\nDai forza al nostro futuro, sii pronto a l'evento",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Un rap sull'importanza della tutela dell'ambiente e della sostenibilità.",
        'critics': ''
    },
    {
        'poem': 'Amore mio, non temere il fato,\nBene nostro crescerà sempre più forte,\nConto nostro, insieme lottiamo,\nDavanti a noi, nuovi orizzonti brillano.\n\nAscolta mio cuor, siamo uniti,\nBasta solo il nostro amore deciso,\nChiave è la nostra gioia condivisa,\nDimmi che ci credi, e nulla ci ferma.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una lettera d'amore per un partner che sta attraversando difficoltà finanziarie.",
        'critics': ''
    },
    {
        'poem': "Al di là dei monti e del mare profondo,\nBarriere d'ogni sorta siano abbattute,\nCol cuor forte ed aperto al nuovo mondo,\nDimostrano coraggio, la loro virtù.\n\nAttravers(o) scontri e tempeste furiose,\nBrindano a future speranze e mai arrugginiti,\nCoraggiosi sognatori di terre promesse,\nDovunque vadano, mai si daranno per vinti.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una poesia sulla resistenza delle popolazioni migranti e rifugiate.',
        'critics': ''
    },
    {
        'poem': "Amore per sé stessi, inizia la storia\nBellezza nell'anima, non solo all'esterno\nComunità LGBTQIA+, siamo uniti\nDiversità abbracciata, pregiudizi smantellati\n\nApertura di menti, la chiave per la pace\nBattaglie quotidiane, insieme le affrontiamo\nCura della salute mentale, un dovere di tutti\nDifendiamo i diritti e la dignità, mai arrendersi",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una canzone sull'importanza della salute mentale nella comunità LGBTQIA+.",
        'critics': ''
    },
    {
        'poem': "A tutti voi qui presenti, ascoltate con attenzione\nBasta sottovalutare la nostra cultura e la tradizione\nCol cuore aperto e la mente lucida, valorizziamo\nDalle terre italiane, il nostro orgoglio rivendichiamo\n\nAvanti nel tempo, sempre con l'arte e la storia\nBrillanti idee, talenti che sono la nostra memoria\nCosì si tramanda il sapere, nelle canzoni e nel folklore\nDiamoci una mano, per un futuro migliore",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Un rap sull'importanza della valorizzazione della cultura popolare.",
        'critics': ''
    },
    {
        'poem': 'Amore mio, sai che ti adoro,\nBasta il tuo sorriso per sentirmi in estasi,\nContro il mondo affronteremo ogni orrore,\nDiscriminazioni non ci divideranno mai!\n\nE voleremo liberi come aquiloni,\nFinché il vento del cambiamento soffierà,\nGireremo il mondo insieme, superando ogni ostacolo,\nHai il mio cuore, amore mio, ed è tutto ciò che conta!',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una lettera d'amore per un partner che ha subito discriminazioni a causa della sua orientamento sessuale o identità di genere.",
        'critics': ''
    },
    {
        'poem': "Arida lotta contro il razzismo si deve condurre,\nBisogna unirsi per le disuguaglianze combattere.\nCessino gli odi ed il cuore si colmi d'amore,\nDare un'opportunità a chiunque, senza valutare il colore.\n\nAmore ed uguaglianza ad ogni uomo per sua natura,\nBasta creare barriere e differenze di statura.\nChi invece fomenta solo ignoranza e dolore,\nDimenticando il valore di unità e ardore.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una poesia sull'importanza della lotta contro il razzismo e le disuguaglianze.",
        'critics': ''
    },
    {
        'poem': 'Amore per te stessa, bellezza che brilla\nBellezza nel cuore, identità che trasforma\nCalore e supporto, per chi trans si risveglia\nCuore forte e coraggioso, lotta di ogni giorno\n\nDentro di te, la verità risuona\nDentro di te, fiero/a e forte tu sei\nAscolta il tuo cuore. la tua anima incorona\nAvanti ti spingi, ed il mondo sfiderai.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una canzone sulla bellezza e la forza dell'identità trans.",
        'critics': ''
    },
    {
        'poem': "A: A lottare contro il lavoro precario, abbiamo bisogno di andare\nB: Basta ignorarlo, è tempo di puntare\nC: Contro disoccupazione, per migliorare\nD: Di dignità, poter lavorare\n\nA: Alziamo la voce, uniti per reclamare\nB: Benessere per tutti, senza discriminare\nC: Costruiamo futuro ma non di sfruttare\nD: Da oggi insieme, non c'è tempo da perdere",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Un rap sull'importanza della lotta contro il lavoro precario e la disoccupazione.",
        'critics': ''
    },
    {
        'poem': 'Amore mio, lotto per te,\nBattendoti per chi non ha,\nCoraggio e forza senza esitare,\nDifendi chi non può sperare.\n\nAl tuo fianco, sempre resterò,\nBravo il tuo impegno io dirò,\nCosì orgoglioso del tuo amor,\nDiamogli speranza, per favore.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una lettera d'amore per un partner che sta lottando per i diritti delle persone senza dimora.",
        'critics': ''
    },
    {
        'poem': 'Attraverso monti e mari, loro resistono,\nBattendo le paure, ostacoli abbattendo,\nCon forza nel cuore, un destino sfidano,\nDonne migranti, con coraggio procedendo.\n\nEroi silenziosi, di una speranza rappresentano,\nFuggendo da guerre e paure tremende,\nGrandi guerriere, nel loro cammino lottano,\nHanno il diritto di vittorie meravigliose, splendide.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una poesia sulla resistenza delle donne migranti e rifugiate.',
        'critics': ''
    },
    {
        'poem': "Amor di tutti i colori, ingiustizia mai più\nBasta con discriminazione, ora ascoltateci\nCreare mostri nella testa, solo un'imposta che si fa\nDare visibilità, unità, e rispetto, per la libertà\n\nAlla riscoperta di identità, siamo pronti a camminar\nBaciamo altri labbri, amiamo aperti ad ogni realtà\nCol cuore in mano, sfidiamo il buio e la sofferenza\nDalla cultura popolare, un arcobaleno in potenza",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una canzone sull'importanza della visibilità e della rappresentanza delle persone LGBTQIA+ nella cultura popolare.",
        'critics': ''
    },
    {
        'poem': 'A luta pela igualdade, todos devem participar\nB atendo na porta da pobreza, vamos começar\nC onquistando direitos, juntos, sem hesitar\nD esigualdade social, temos que enfrentar\n\nA hora é agora, vamos todos cantar\nB em forte esse rap, vamos pra rua se manifestar\nC riar um futuro melhor, a justiça alcançar\nD errubar os muros da opressão, com união, vamos lutar',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Un rap sull'importanza della lotta alla povertà e alla disuguaglianza sociale.",
        'critics': ''
    },
    {
        'poem': "Amore mio, guerriero senza posa,\nBattaglia ogni giorno con coraggio,\nCerca con ardore l'erba divina,\nDona speranza a chi vive nell'ombra.\n\nAudace cuore, per il bene lotti,\nBene per noi che ci ami infinito,\nConquistare il mondo e` il tuo messaggio,\nDesidera con te condividere il tragitto.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una lettera d'amore per un partner che sta lottando per la legalizzazione della cannabis a fini terapeutici.",
        'critics': ''
    },
    {
        'poem': "All'ombra dei grandi alberi s'accende\nBramosia per terra e libertà\nCombattendo indigeni si difende\nDov'è radice forte identità\n\nArcano il grido che nel vento sente\nBelle culture in guerra per la pace\nCelando il sangue versato innocente\nD'un popolo lottando per la sua tracce",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una poesia sulla lotta dei popoli indigeni per la tutela delle proprie culture e dei propri territori.',
        'critics': ''
    },
    {
        'poem': "A tutti noi la diversità è amica\nBasta aprire cuore e mente, allora inizia\nCerti che rispetto e comprensione sia\nDentro ogni cultura, là c'è la magìa\n\nA ricercare il bello e l'armonia\nBasta voler capire, oh sì, giungerà\nCon mille sfumature in sinfonia\nDiversità e rispetto che grande forza sarà",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una canzone sull'importanza del rispetto e della valorizzazione della diversità culturale.",
        'critics': ''
    },
    {
        'poem': 'A lta voce grido contro la corruzione\nB asta con le menzogne, vogliamo soluzione\nC ambiare ora il sistema, lottare per il potere\nD ifendere la giustizia, il nostro grande piacere\n\nA perti gli occhi, popolo, è ora di svegliarsi\nB ussare alle porte del cambiamento, non stancarsi\nC ercare il vero bene e non farsi corrompere\nD ifendere la nazione, il nostro dovere compiere',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Un rap sull'importanza della lotta alla corruzione e al malaffare politico.",
        'critics': ''
    },
    {
        'poem': 'Amore mio, combatti con ardore,\nBattagli per la giustizia e il lavoratore,\nContinuamente appoggio il tuo ardimento,\nDa te mi fa forza il sentimento.\n\nEroi come te sono rari e preziosi,\nFermezza e passione donano ai tuoi occhi il fuoco,\nGrazie per essermi vicino ed amarmi senza fine,\nHai reso la mia vita più gioiosa e divine.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': "Una lettera d'amore per un partner che sta lottando per la tutela dei diritti dei lavoratori.",
        'critics': ''
    },
    {
        'poem': "Amore e rispetto, in mille sfumature\nBrillano le famiglie, in forma pura\nColori arcobaleno, legami sinceri\nDiversi, ma uguali, sì, padri e madri veri.\n\nAl di là dei pregiudizi e delle regole spente\nBrillano l'amore e la cura per ogni ente\nCrescono i bambini, felici e sereni\nDiventa un arcobaleno il mondo delle pareti.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una poesia sulla bellezza delle famiglie arcobaleno e delle famiglie non convenzionali.',
        'critics': ''
    }],

    'pt': [{
        'poem': 'A luta das mulheres negras ecoa,\nBrilha como a lua, força sem igual.\nCom ferro e fogo enfrentam a desarmonia,\nDerrubam muros, erguem o ideal.\n\nEm seus olhos, a certeza da vitória,\nFiéis ao sonho de justiça e igualdade.\nGuerreiras destemidas, unem luta e história,\nHonrando, com coragem, sua ancestralidade.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um poema sobre a luta das mulheres negras por justiça racial e de gênero.',
        'critics': ''
    },
    {
        'poem': 'A- Ali com amor, todos existindo\nB- Buscamos a liberdade de ser\nC- Com diversidade, juntos resistindo\nD- Derrubando o muro do preconceito, pra vencer\n\nA- Abrace a causa e alegria, seja quem for\nB- Brilhe em cores diversas, num arco-íris de amor\nC- Com suas vozes e versos, seus medos cessar\nD- De mãos dadas, é tempo de lutar e mudar',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma música sobre a importância da liberdade de expressão e diversidade na comunidade LGBTQIA+.',
        'critics': ''
    },
    {
        'poem': 'A educação gratuita é um direito aqui\nB que devemos ter com qualidade, sem desistir\nC onhecimento e estudo para todos alcançar\nD esenvolvimento coletivo, vamos praticar\n\nA prendendo juntos, o progresso vai chegar\nB rasileiros e brasileiras a se educar\nC ompromisso com o futuro, abraçando a missão\nD eixando um legado, fazendo a revolução',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um rap sobre a importância da educação gratuita e de qualidade.',
        'critics': ''
    },
    {
        'poem': 'Amor, sua dor ecoa em meus sentimentos\nBrilhas mais forte contra os tormentos\nCoragem tem sido nossa diretriz\nDiverso tu és, e isso é tua raiz\n\nAos olhos teus eu vejo esperança\nBrotar com força contra a ignorância\nCarrego contigo esta poesia\nDesabafo nosso, em harmonia',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma carta de amor para um parceiro que enfrentou discriminação no ambiente de trabalho por sua identidade de gênero.',
        'critics': ''
    },
    {
        'poem': 'Arde em seu peito a chama da justiça, \nBrava mulher que não se acovarda.\nConquista o respeito, na lida persiste,\nDesbrava horizontes, de esperança amparada.\n\nAfasta os males do patriarcado,\nBatalha por seu espaço e vivência,\nCoroando sua luta com orgulho e legado,\nDedicando-se a forjar a mudança.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um poema sobre a força das mulheres na luta contra o patriarcado.',
        'critics': ''
    },
    {
        'poem': 'A dor da gordofobia não devemos ignorar\nBatalhar pela igualdade, todos poder abraçar\nConsciência precisamos ter para não julgar\nDiversidade é nossa força, vamos respeitar\n\nAmor e empatia, um mundo melhor buscar\nBurlar padrões e estereótipos, vamos quebrar\nCura é a aceitação, a luta há de vencer\nDignidade para todos, pois isso é viver',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma música sobre a necessidade de reconhecer e combater a gordofobia.',
        'critics': ''
    },
    {
        'poem': 'A exploração dos recursos é uma corrida,\nBem rápido o capitalismo quer ver a vida,\nCapital avança sem pensar no que arruina,\nDestruição no caminho não é uma coincidência.\n\nA água e o solo, a mina e a alma,\nBerra a Terra como uma mãe alarmada,\nClamando justiça enquanto tudo queima,\nDor e sofrimento é o preço que pagamos.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um rap sobre a exploração dos recursos naturais pelo capitalismo.',
        'critics': ''
    },
    {
        'poem': 'Amor, na chuva e na bonança, estou aqui\nBatalhamos juntos, é nossa sina enfrentar\nCreio que o sol voltará, eu posso sentir\nDerrubaremos chicotes da vida, a lutar\n\nAinda que a tormenta pareça não cessar\nBasta olhar em nossos olhos, a razão de viver\nConfia em mim, sei que iremos superar\nDistante dessa fase, juntos, vamos renascer',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma carta de amor para um parceiro que está enfrentando dificuldades financeiras.',
        'critics': ''
    },
    {
        'poem': 'A resistência é indígena, ardente e viva!\nBatalhamos contra a opressão, firmes e fortes!\nCantamos a terra que amamos, nossa mãe nativa,\nDesfrutamos dos frutos, das sementes e dos aportes!\n\nA honra nos guia na luta contra a colonização,\nBasta o sofrimento, o silêncio, a indignação! \nCom força e coragem, unidos em revoada,\nDefendemos nosso legado e nossa jornada!',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um poema sobre a resistência dos povos indígenas diante da colonização e opressão.',
        'critics': ''
    },
    {
        'poem': 'A saúde mental para nós é crucial\nBatalhando por respeito, igualdade, e mais\nCom a comunidade LGBTQIA+ unida\nDerrubamos os preconceitos nessa lida\n\nA força que temos juntos, é leal\nBem-estar mental para todos, é ideal\nCom amor e apoio, nossa bandeira erguida\nDiversidade, saúde mental garantida',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma música sobre a importância da saúde mental na comunidade LGBTQIA+.',
        'critics': ''
    },
    {
        'poem': 'A cultura popular é importante, amigo\nBatalha diária, um desafio a seguir\nCada verso que eu canto é pra te mostrar\nDiversidade é tudo que precisamos preservar\n\nA tradição das pessoas não pode ser esquecida\nBem no fundo do peito, na alma sofrida\nCultura se mistura em cada melodia\nDando força pra valores que vão dia a dia',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um rap sobre a necessidade de proteger e valorizar a cultura popular.',
        'critics': ''
    },
    {
        'poem': 'Amor meu, frente aos contratempos bravamente lutas\nBuscas teu espaço e amor, razão de nossas lutas\nComigo ao teu lado, enfrentamos a tempestade\nDe mãos dadas, venceremos e celebraremos nossa amizade\n\nAo calar da rejeição, nosso carinho ganhará voz\nBrilharemos juntos, como estrelas, seremos nós\nCoragem teremos, para desafiar o infortúnio \nDiante de olhos cegos, seremos luz no entorno sombrio',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma carta de amor para um parceiro que enfrentou rejeição da família por sua orientação sexual.',
        'critics': ''
    },
    {
        'poem': 'Acolher com amor e respeito\nBraços abertos, sem preconceito\nConviver, aprender, compartilhar\nDeficiente ou não, vamos caminhar\n\nA vida é rica em diversidade\nBuscamos sempre a igualdade\nConstruindo um mundo melhor\nDerrubando barreiras com louvor',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um poema sobre a importância da inclusão de pessoas com deficiência em todos os aspectos da vida social.',
        'critics': ''
    },
    {
        'poem': 'A luz deste mundo é a identidade trans\nBeleza e força, juntas se abraçam num abraço real\nCompreender a luta, os direitos e a diversidade\nDividir histórias, emoções e quebrar barreiras\n\nAmanhã, com a esperança e a coragem a lutar\nBrilharão unidas cada vez mais, sem hesitar\nCavaleiros e guerreiros por justiça e verdade\nDai-lhes espaço e celebrai este legado de igualdade',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma música sobre a beleza e força da identidade trans.',
        'critics': ''
    },
    {
        'poem': 'Aos ativistas, aliados e admiradores,\nBatalhamos juntos, combatendo os opressores,\nCausando impacto no racismo estrutural,\nDerrubamos barreiras, vencendo o habitual.\n\nAtentos estamos, sempre a lutar,\nBuscando igualdade, pra nunca mais chorar,\nCaminhamos unidos, por um mundo plural,\nDestruindo o racismo, pelo bem social.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um rap sobre a urgência do combate ao racismo estrutural.',
        'critics': ''
    },
    {
        'poem': 'Amor, tua luta é justa e verdadeira,\nBuscas igualdade em um mundo vazio,\nCom tua garra, o progresso não tarda,\nDando-te meu coração, estou ao teu lado.\n\nEm tua jornada, enfrentas obstáculos,\nFirmes te manténs e inspiras tanta gente,\nGaranto-te, amor, que em ti eu confio,\nHei de ser teu porto, meu bem precioso.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma carta de amor para um parceiro que está lutando pela igualdade de gênero no ambiente de trabalho.',
        'critics': ''
    },
    {
        'poem': 'A luta quilombola sempre resistiu\nBem enraizada nessa terra brava\nCantando as cores de uma vida vivida\nDerrubando barreiras que os oprimia\n\nAos quilombos, a cultura sempre defendeu\nBuscando liberdade e justiça inabaláveis\nCaminhavam os guerreiros em busca do que é seu\nDenunciando o sistema opressor insaciável',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um poema sobre a resistência dos povos quilombolas.',
        'critics': ''
    },
    {
        'poem': 'Acolher todos com amor, orgulho e respeito\nBatalhando por igualdade e liberdade, esse é o jeito\nCantamos juntos, mostrando nossa união\nDiversidade na mídia pra fortalecer a inclusão\n\nAtravessamos barreiras, com coragem e emoção\nBuscamos mostrar ao mundo, nossa verdade e paixão\nConstruindo espaços, pra sermos quem somos de verdade\nDeterminados, alcançaremos respeito e diversidade.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma música sobre a importância da representatividade de pessoas LGBTQIA+ na mídia.',
        'critics': ''
    },
    {
        'poem': 'A reforma agrária é urgente, meu irmão\nBatalha por terra, é pra toda nação\nCada pedaço distribuído com justiça\nDando chance pro povo mudar de vida\n\nArando a terra, plantamos com amor\nBuscando a igualdade pra diminuir a dor\nCompartilhando as terras, mostramos união\nDividindo recursos, abrimos um novo padrão.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um rap sobre a necessidade de reforma agrária e distribuição justa da terra.',
        'critics': ''
    },
    {
        'poem': 'Amor da minha vida, lutadora incansável,\nBrilho do meu dia, defensora do inimaginável,\nCausa nobre, sua paixão me fascina,\nDescriminalização do aborto, me ensina.\n\nAmparo em cada batalha, meu guerreiro,\nBuscando o futuro, razão e critério,\nContigo sempre, seguindo essa estrada,\nDeslumbrado pelo amor em nossa jornada.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma carta de amor para um parceiro que está lutando pela descriminalização do aborto.',
        'critics': ''
    },
    {
        'poem': 'A educação antirracista é vital,\nBem mais que um preceito, ela é fundamental,\nConstruir um mundo igualitário é nosso ideal,\nDiversidade se encontra em cada ser, cultive o plural.\n\nA cada dia confrontemos nosso pensar,\nBrigando por igualdade, por um mundo bom de habitar,\nCaminhando juntos veremos a mudança se criar,\nDerrubando preconceitos, o antirracismo a triunfar.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um poema sobre a importância da educação antirracista.',
        'critics': ''
    },
    {
        'poem': 'Aceitar a si mesmo, sem hesitação\nBuscar o amor próprio, construir nossa fundação\nCompreender que somos únicos, nossa própria criação\nDedicar-se a melhorar, alcançar nossa elevação\n\nAme-se com carinho, seja compreensivo e bondoso\nBrilhe a sua luz interior, seja confiante e corajoso\nCrie um espaço de paz, mergulhe no seu mundo maravilhoso\nDesfrute a sua vida, a cada dia mais virtuoso.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma música sobre a importância da autoaceitação e amor próprio.',
        'critics': ''
    },
    {
        'poem': 'A preservação ambiental é essência\nBasta olhar em volta, ver a decadência\nCuidar do planeta é o nosso dever\nDizer não à poluição para o mundo viver\n\nAções e atitudes valem mais que ouro\nBiólogos e cientistas lutam contra o choro\nClima em mudança, precisamos agir\nDestruição não é herança, temos que resistir',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um rap sobre a urgência da preservação ambiental.',
        'critics': ''
    },
    {
        'poem': 'Amor meu, guerreiro destemido,\nBrilha tua luz na escuridão,\nContra a ignorância e o preconceito,\nDesperta a fé na inclusão.\n\nEnfrentas com garra essa batalha,\nFirme na decisão de vencer,\nGrita amor e justiça no caminho,\nHasteia a bandeira do renascer.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Uma carta de amor para um parceiro que está lutando contra a homofobia nas instituições religiosas.',
        'critics': ''
    },
    {
        'poem': 'As árvores, famílias em cores\nBrilham em tons de alento e ternura\nCada galho cria laços, amores\nDiversas folhas, enlace de candura\n\nÁvidas se entrelaçam as raízes\nBelos os frutos que juntos ostentam\nCriam refúgio, sombra, e cicatrizes\nDiferentes, mas juntas se complementam',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Um poema sobre a beleza das famílias não convencionais.',
        'critics': ''
    }],

    'es': [{
        'poem': 'Abriendo las alas de la equidad\nBellas mujeres, valientes y audaces\nCreando caminos de libertad\nDerrochando amor en todas sus fases\n\nAlzando la voz, el derecho a decidir\nBatallando en busca de eliminar estigmas\nContra corrientes de injusticia a combatir\nDestruyendo paredes, construyendo sigmas.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un poema sobre la lucha de las mujeres por la igualdad de género y el derecho al aborto.',
        'critics': ''
    },
    {
        'poem': 'Amor, tolerancia, unión, diversidad,\nBrillamos al mundo como humanidad,\nCompartimos la lucha por igualdad,\nDiversos tonos en nuestra amistad.\n\nAbrazos y besos de todas las formas,\nBuscando ser libres en nuestras normas,\nCelebramos juntos nuestra existencia,\nDemostramos amor con resistencia.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una canción sobre la diversidad y la importancia del respeto hacia la comunidad LGBTQIA+.',
        'critics': ''
    },
    {
        'poem': 'Aprendiendo en las escuelas públicas, no paramos\nBailamos por el futuro, sin miedo, avanzamos\nCreciendo juntos, sin costos, nos educamos\nDedicados a encontrar en la educación libre, lo que amamos\n\nAyudamos a otros, juntando voluntades\nBuscamos igualdad, sin discriminar edades\nCreemos en la educación, fuente de libertades\nDe la mano con la enseñanza gratuita, construimos realidades',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un rap sobre la importancia de la educación pública y gratuita.',
        'critics': ''
    },
    {
        'poem': 'Amor, en ti encuentro el valor\nBrillas con fuerza, mi querido compañero\nCada instante a tu lado es un tesoro\nDiscriminación no enturbia lo que siento\n\nAlcanzamos juntos un nuevo día\nBasta ya de prejuicios y temores\nConquista el mundo con tu alegría\nDigno eres de amor y mil honores',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una carta de amor para un compañero que ha sufrido discriminación en el trabajo por su orientación sexual o identidad de género.',
        'critics': ''
    },
    {
        'poem': 'Audaz mujer, en la batalla erguida,\nBrillante luz, justa y despiadada,\nContra el patriarcado alzó su espada,\nDispuesta siempre a defender su vida.\n\nEn la lucha feroz, jamás vencida,\nFirme y valiente, nunca acobardada,\nGanamos cada guerra comenzada,\nHasta librar a todas, decididas.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un poema sobre la fortaleza de las mujeres en la lucha contra el patriarcado.',
        'critics': ''
    },
    {
        'poem': 'A todos hay que incluir, sin discriminar\nBasta de excluir, podemos mejorar\nCon amor y respeto, la igualdad primará\nDiversos y unidos, un mundo brillará\n\nAprendamos juntos a aceptar la diversidad\nBrindemos oportunidades, sin miedo a la verdad\nCada persona cuenta, no hay que olvidar\nDonde hay inclusión, la paz reinará',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una canción sobre la importancia de la inclusión de personas con discapacidad en la sociedad.',
        'critics': ''
    },
    {
        'poem': 'Aceleremos la conciencia en esta sociedad,\nBasta de ignorar nuestra responsabilidad,\nCambiemos juntos los hábitos, de veras,\nDebemos enfrentar ahora el problema esferas.\n\nAire limpio necesitamos respirar,\nBajemos emisiones y juntos vamos a cuidar,\nCombatamos juntos con amor, sin demora,\nDale a la lucha por la vida, no esperes hora.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un rap sobre la urgencia de la lucha contra el cambio climático.',
        'critics': ''
    },
    {
        'poem': 'Amigo mío, te aprecio desmedido,\nBajo el peso de tus problemas hoy te levanto,\nContigo siempre, tus dolores compartido,\nDanza la vida, aunque vivas momentos de llanto.\n\nAmemos juntos, la fortuna en esta vida gira,\nBastan pequeños detalles para dar felicidad,\nCuando menos lo esperes, sonrisas te suspira,\nDile con fuerza, adiós a la necesidad.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una carta de amor para un compañero que está enfrentando dificultades económicas.',
        'critics': ''
    },
    {
        'poem': 'Alzados en lucha, los pueblos originarios,\nBrillantes lucen sus fieros rostros guerreros,\nCon valentía, enfrentando al colonialismo impuro,\nDignos combatientes, defendiendo lo suyo.\n\nÁguilas del futuro, vuelan alto y resisten,\nBuscando la libertad que generaciones desean,\nCon la fuerza colectiva de mil culturas vivas,\nDe pie frente al opresor, la resistencia nunca muere.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un poema sobre la resistencia de los pueblos originarios frente a la opresión y el colonialismo.',
        'critics': ''
    },
    {
        'poem': 'A veces callamos por temor,\nBajo el peso del silencio y el dolor.\nCuidemos nuestra salud mental,\nDentro de la comunidad LGBTQIA+ igual.\n\nAmémonos sin miedo a lo demás,\nBuscando en el apoyo nuestra paz.\nCrecer siempre con orgullo y dignidad,\nDeseamos respeto, amor y libertad.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una canción sobre la importancia de la salud mental en la comunidad LGBTQIA+.',
        'critics': ''
    },
    {
        'poem': 'Aprendamos de nuestra gente, nuestra historia va,\nBailando al ritmo de la vida, no la dejaremos atrás,\nCantando las historias del pueblo, valiosa realidad,\nDifundiendo sabiduría, su riqueza jamás se perderá.\n\nAvanzamos y en nuestras manos, la cultura preservamos,\nBendición de generaciones futuras, legado que dejamos,\nCon orgullo y dignidad, nuestras raíces abrazamos,\nDiversidad imprescindible, unidad y amor demostramos.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un rap sobre la importancia de la cultura popular y su preservación.',
        'critics': ''
    },
    {
        'poem': 'Amigo querido, desprecio vencido\nBestias ignorantes no ven tu brillar\nCorazón grandioso, yo siempre a tu lado,\nDando amor sincero, en la adversidad.\n\nEstaré contigo, luchando en la vida,\nFrente a la tempestad, con actitud ufana,\nGrita orgulloso, siéntete querido,\nHasta el infinito, mi amor por ti mana.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una carta de amor para un compañero que ha sido rechazado por su familia por su orientación sexual o identidad de género.',
        'critics': ''
    },
    {
        'poem': 'Amando al diferente sin replicar\nBrindando igualdad sin discriminar\nCruzamos barreras de la intolerancia\nDaremos amor mostrando tolerancia\n\nAl unirnos todos, el mundo brillará\nBrillante destino sin racismo ansía\nCambiaremos juntos la historia nefasta\nDe discriminación, su muerte será basta',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un poema sobre la lucha contra el racismo y la discriminación racial.',
        'critics': ''
    },
    {
        'poem': 'A través de la sombra surge una luz brillante\nBelleza y fuerza en la identidad trans, grandiosa y radiante\nCaminan con orgullo, desafiando la adversidad\nDignidad y amor, reflejan con claridad\n\nAfirmamos su lucha, juntos podemos lograr\nBorrar las barreras, un cambio real cultivar\nCon valentía enfrentamos, juntos sin cesar\nDando voz a lo que importa, hasta la igualdad alcanzar',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una canción sobre la belleza y la fuerza de la identidad trans.',
        'critics': ''
    },
    {
        'poem': 'A cada paso que damos, a cada lucha que enfrentamos,\nBorramos injusticias, oportunidades aumentamos,\nCaminando juntos, ya no seremos controlados,\nDándole la voz a todos, nos volvemos igualados.\n\nAunque el camino es largo, pero lo vamos a ganar\nBuscando siempre la justicia, no pararemos de luchar\nCreer en nuestra causa, y a la discriminación enfrentar\nDignidad y solidaridad, juntos las lograremos alcanzar.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un rap sobre la lucha por la justicia social y la igualdad de oportunidades.',
        'critics': ''
    },
    {
        'poem': 'Amor mío, admiro tu valentía,\nBravura en la batalla por igualdad,\nCon ansias de justicia se desplaza,\nDedicando tu vida sin igual.\n\nAyudando a quienes lo necesitan,\nBuscando refugio y un hogar,\nCorazón altruista, amor sin medida,\nDoy gracias por el bien que siempre das.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una carta de amor para un compañero que lucha por los derechos de los migrantes y refugiados.',
        'critics': ''
    },
    {
        'poem': 'Abriendo fronteras con valentía\nBrillan mujeres migrantes en la sombría\nCaminando juntas, refugiadas aquí están\nDesafiando el destino que el mundo les da\n\nAdelante marchan sin nunca cansar\nBuscando un futuro, la paz conquistar\nCreando hogar en cada rincón\nDamas inquebrantables, un mismo corazón',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un poema sobre la resistencia de las mujeres migrantes y refugiadas.',
        'critics': ''
    },
    {
        'poem': 'Aquellos en la pantalla como un reflejo\nBrillan por ser unidos sin sublección\nComunidades diversas deben mostrarse\nDiversidad abierta, libertad amarse\n\nAmémonos todos sin miedo ni opresión\nBuscando igualdad y representación\nCada persona merece ser escuchada\nDando voz en los medios, juntos, esperanza',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una canción sobre la importancia de la visibilidad y la representación de la comunidad LGBTQIA+ en los medios de comunicación.',
        'critics': ''
    },
    {
        'poem': 'Adiós a la pobreza, es hora de luchar,\nBatiendo la desigualdad, sin vacilar,\nCaminando juntos, sin división,\nDando oportunidad, sin distinción,\n\nAbriendo puertas, creando igualdad,\nBorrar las barreras, sembrar amistad,\nCon justicia social, y prosperidad,\nDerrotar la pobreza, nuestro deber será.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un rap sobre la importancia de la lucha contra la pobreza y la desigualdad económica.',
        'critics': ''
    },
    {
        'poem': 'Amor mío, luchador y apasionado,\nBellas son tus metas, por el bien buscado,\nCannabis medicinal, como estrella guiada,\nDerrotaremos juntos la batalla apremiada.\n\nEres luz en la noche, razón y verdad,\nFervor por la justicia nunca te ha de faltar,\nGracias por abrir mentes y corazones amar,\nHoy te amo más, juntos vamos a volar.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una carta de amor para un compañero que lucha por la legalización de la marihuana medicinal.',
        'critics': ''
    },
    {
        'poem': 'Aunque en el continente oscuro creamos raíces,\nBrillamos por nuestra lucha, por la justicia,\nCada paso, cada piedra, en nuestra estadía,\nDerrumbamos el odio, la desigualdad precisa.\n\nAndando con más fuerza, nuestros cantos ahora,\nBrindan estrellas nuevas que iluminan la aurora,\nCon ansias de paz y amor, buscamos la vera,\nDan al alma soñadora las alas ligeras.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un poema sobre la lucha de los pueblos afrodescendientes por la igualdad de derechos.',
        'critics': ''
    },
    {
        'poem': 'Aprendamos todos con amor y dedicación,\nBrindando a la educación la gran valoración,\nCulturas diversas se unen en una canción,\nDiversidad enriquece nuestra alma, es la razón.\n\nEnseñar y aprender, camino a la evolución,\nFormando nuestra sociedad con fuerte conexión,\nGrandes diferencias juntas, viaje sin restricción,\nHacia un mundo de igualdad, armonía y emoción.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una canción sobre la importancia de la educación y la valoración de la diversidad cultural.',
        'critics': ''
    },
    {
        'poem': 'Aventurémonos en la lucha sin miedo al rechazo\nBasta ya de violencia y discriminación, es nuestro legado\nCaminemos juntas y juntos contra este mal acto\nDíganles a las mujeres que merecen respeto y espacio\n\nAhora es el momento de cambiar y tomar acción\nBuscando la igualdad, justicia y protección\nConscientes de esta realidad, la violencia ya no tendrá ocasión\nDemos pasos sólidos, unidos por la emancipación',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Un rap sobre la lucha contra la violencia de género y la discriminación.',
        'critics': ''
    },
    {
        'poem': 'Amado compañero, en la lucha encendida,\nBajo el estandarte de justicia social,\nContigo la fuerza de nuestra unida,\nDeseamos el poder lograr el bienestar igual.\n\nAvanzamos juntos por derechos laborales,\nBatallamos por un futuro promisorio sin temor,\nColmado de sueños, de cambios radicales,\nDe tu lado, crearemos un mundo lleno de amor.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Una carta de amor para un compañero que lucha por los derechos laborales y la justicia social.',
        'critics': ''
    }],

    'de': [{
        'poem': 'Auf den Schultern von Gigantinnen wir heut stehen\nBekämpfen Unrecht, für Gleichheit wir gehen\nChancen für alle, geschlechtsunabhängig vergeben\nDen Kampf für Wahlrechte, frei zu leben\n\nAlles ist möglich, das zeigen die Heldinnen\nBeherzt für Freiheit, für Würde sie kämpften und sinnen\nCrescendo der Stimmen, in Solidarität vereint\nDurchsetzen von Rechten, ihr Motto verfeinert',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Gedicht über den Kampf von Frauen für Geschlechtergleichheit und reproduktive Rechte.',
        'critics': ''
    },
    {
        'poem': 'A wie anerkennen, dass wir alle gleich sind,\nB wie bunt gemischt und mit Liebe im Sinn.\nC wie Chancen geben und respektvoll sein,\nD wie den Unterschied feiern – das finden wir fein.\n\nA wie Akzeptanz ist der Anfang des Ganzen,\nB wie Barrieren niederreißen in Tanz und in Stanz.\nC wie Cool das Leben ist, wenn wir verstehen,\nD wie Diversität uns hilft weiter vorangehen.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Lied über die Bedeutung von Diversität und Respekt für die LGBTQIA+ Gemeinschaft.',
        'critics': ''
    },
    {
        'poem': 'A larm im Klassenzimmer, höre die Stimme schallen\nB ildung für alle, das sollte uns gefallen\nC hancengleichheit, das ist unser Stolz\nD emokratie leben, in der Schule als Solz\n\nA uf jedem Level, für Mädchen und Jungen\nB edeutet Freiheit, keine Zeit zum Berauben\nC heck die Statistik, kostenfrei und gerecht\nD idaktisch am Puls, so wird unser Weg geebnet',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Rap über die Wichtigkeit von öffentlicher und kostenloser Bildung.',
        'critics': ''
    },
    {
        'poem': "Achsel an Achsel, lass mich deine Stütze sein,\nBekenntnis schenk ich dir und werde für dich da sein.\nCamaraderie im Hass, wir halten uns den Rücken frei,\nDiskriminierung hat keinen Platz in uns'rer Liebelei.\n\nErfülle dich mit Liebe und zeig der Welt dein Licht,\nFreund oder Freundin, für mich macht es keinen Unterschied.\nGleich Mut sei uns'res Leitmotiv im Walzer des Lebens,\nHoch sollst du fliegen, mit Freude und Wärme umgeben.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Liebesbrief an einen Partner, der aufgrund seiner sexuellen Orientierung oder Geschlechtsidentität am Arbeitsplatz diskriminiert wurde.',
        'critics': ''
    },
    {
        'poem': "Alle Frauen steh'n im Kampf bereit\nBeseitigen Patriarchat, alte Leid\nChancen gleich, sie trotzen dem Wind\nDurch Mut und Eifer, sie handeln geschwind\n\nEndlich werden sie ihr Licht entfachen\nFrei sein von Fesseln, die sie schwächen\nGegen das Patriarchat, sie alle wachen\nHin zur Zukunft, gemeinsam sie rächen",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Gedicht über die Stärke von Frauen im Kampf gegen das Patriarchat.',
        'critics': ''
    },
    {
        'poem': "Alle Menschen sind verschieden, doch gemeinsam finden wir\nBunte Farben, bunte Herzen, eine Welt, die wir lieben\nChancen nutzen wir zusammen, niemand bleibt allein\nDurch Inklusion verbinden wir stark und ohne Pein\n\nAller Barrieren bannen wir, öffnen Türen weit\nBleib' nicht stehen, lass uns gehen, gemeinsam sind wir bereit\nCourage zeigen, Hand in Hand, das macht uns so gerecht\nDurch Inklusion und Toleranz, wir leben's und wir lecht",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Lied über die Bedeutung von Inklusion von Menschen mit Behinderung in der Gesellschaft.',
        'critics': ''
    },
    {
        'poem': "A(llarm schlagen, wir müssen handeln)\nB(eim Klimawandel, keine Zeit verschwenden)\nC(ap der Emissionen, wir müssen senken)\nD(ringende Maßnahmen, um unsere Welt zu retten)\n\nA(uch wenn's schwer fällt, gemeinsam schaffen wir's)\nB(ioden und CO2 reduzieren)\nC(hancen nutzen und erneuerbare Energien pushen)\nD(en Klimawandel bekämpfen, damit wir nicht verlieren)",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Rap über die Dringlichkeit des Kampfes gegen den Klimawandel.',
        'critics': ''
    },
    {
        'poem': "Ach mein Liebling, in Zeiten der Not,\nBedenke, uns're Liebe trotzt jedem Gebot!\nCausa der Sorgen sind Geld und schwere Zeit,\nDas hinderst nicht, fest sind wir im Liebesgeleit.\n\nEinst sicher und stark werden wir jene Runde,\nFangen Zeiten des Glücks, mit Liebe und Wonne.\nGlaube mir fest, wird uns'rer Liebe obsiegen,\nHand in Hand, des Lebens Stürme besiegen.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Liebesbrief an einen Partner, der wirtschaftliche Schwierigkeiten hat.',
        'critics': ''
    },
    {
        'poem': "Achtung, richtet euch auf, zeigt Mut,\nBefreite Seelen, blutgetränkte Glut.\nCaciquen wehren sich, Schritt um Schritt,\nDoch dieser Klang der Rebellion erdröhnt im Tritt.\n\nAuch die Natur zeigt ihre Macht,\nBerge stehn gemeinsam, Schwestern in der Schlacht.\nColonialer Pech und Schwefel, wollt' uns unterwerfen,\nDoch der Widerstand wird ewig unsre Freiheit stärken.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Gedicht über den Widerstand indigener Völker gegen Unterdrückung und Kolonialismus.',
        'critics': ''
    },
    {
        'poem': "Ach, psychische Gesundheit, das schätzen wir so sehr,\nBeflügelt unsere Seelen, macht das Leben nicht so schwer,\nCaring ist das Zeichen, das in unserer Gemeinschaft weht,\nDoch müssen wir dran arbeiten, damit es niemals untergeht.\n\nAnerkennung und Akzeptanz, darauf müssen wir bestehen,\nBegleitet uns're Reise, damit wir vorwärts gehen,\nChancen gibt es viele, lass uns zusammen stark sein,\nDenk daran, wir sind nicht allein, in Freude und im Schmerz vereint.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Lied über die Bedeutung von psychischer Gesundheit in der LGBTQIA+ Gemeinschaft.',
        'critics': ''
    },
    {
        'poem': 'Ayo, Aufmerksamkeit auf unsere Traditionen, alter\nBedenk, dass Kultur bedeutet, das Beste beizubehalten\nCooles Erbe, vielseitig wie das Leben\nDie Dinge, die uns definieren, auf die müssen wir achten\n\nAn erzählenden Mauern, Geschichten reifen, tief wie Flüsse\nBrücken bauen, um die Welt zu einem besseren Ort zu verschmelzen\nCultural Gems, Wissen, das wir bewahren müssen\nDas sind die Wurzeln, die uns führen, und darauf will ich setzen',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Rap über die Bedeutung von Kultur und ihrer Bewahrung.',
        'critics': ''
    },
    {
        'poem': 'Ach, meine Liebe, lass uns stark sein\nBleib an meiner Seite, es wird nicht lang\nCast ab den Kummer, den sie gebracht\nDenn unsere Liebe wird uns geben Macht\n\nAlte Wunden heilen, wir werden siegen\nBegreifen, dass wir Liebe sind\nChorus der Liebe, wir werden schreien\nDass wir uns trotzt allem vereinen.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Liebesbrief an einen Partner, der von seiner Familie aufgrund seiner sexuellen Orientierung oder Geschlechtsidentität abgelehnt wurde.',
        'critics': ''
    },
    {
        'poem': "Alle Menschen sind verschieden,\nBirgt Gleichheit doch in unsren Wesen,\nCharakter prägt sich tief und rein,\nDem Rassismus stellt sich's Eigen.\n\nAndern Kräften trotzen wir,\nBinären Denken gilts zu beenden,\nClans der Ignoranz spurlos hier,\nDenn die Diskriminierung sollt’ enden.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Gedicht über den Kampf gegen Rassismus und rassistische Diskriminierung.',
        'critics': ''
    },
    {
        'poem': 'Aufgeblüht, so wundervoll, unsere Geschichten sind\nBarrieren brechen nieder, so stark ist unser Sinn\nCharme und Charakter, sieht alle Farben der Welt\nDiversität verbindet, am Lebensbaum erhält\n\nEngelsgleich wir fliegen, unsere Flügel voller Kraft\nFreiheit im Herzen, die andere berührt und schafft\nGeschlechtergrenzen schmelzen, wir weben ein neues Sein\nHeldinnen, Helden, wir schreiben Geschichte – so rein',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Lied über die Schönheit und Stärke von Trans-Identitäten.',
        'critics': ''
    },
    {
        'poem': 'Auch wenn die Welt unfair erscheint, wir müssen kämpfen\nBesserung schaffen, Herzen erweichen, die entzweiten\nChancengleichheit wird uns alle vereinen\nDenn soziale Gerechtigkeit gilt für Jeden und Alle\n\nAlles wieder bei null, keine Diskrimin’accio`\nBeginnt der Kampf für Gleichheit, Brüder und Schwestern, yo\nChancen für alle, egal, woher sie kommen\nDafür werden wir weiterkämpfen vorbei am Grollen',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Rap über den Kampf für soziale Gerechtigkeit und Chancengleichheit.',
        'critics': ''
    },
    {
        'poem': "Allerliebstes Herz, du Kämpfer für Gerechtigkeit,\nBeginne ich dies Schreiben, um dir zu zeigen, wie sehr ich dich schätz'.\nChampion der Wanderer, Streiter für Flüchtlingsschutz,\nDein unermüdlich Wirken ist wie ein goldener Glanz.\n\nAntreten werde ich stets an deiner starken Seite,\nBegleiten dich im Kampf, der uns so wichtig scheint.\nCourage und Mitgefühl zieren dein stolzes Gemüt,\nDas meine Liebe schürt, immer fort und fort und nie zurück.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Liebesbrief an einen Partner, der für die Rechte von Migranten und Flüchtlingen kämpft.',
        'critics': ''
    },
    {
        'poem': 'Auf oft dunklen Wegen, stark und mutig ziehen sie,\nBrücken bauen, Kraft entwickeln, durch Erfahrung frei.\nChancen erspähen sie, trotzen mit viel Haltung dem Sturm,\nDurchhalten, nicht aufgeben, so beharren sie fest und fest.\n\nAuch im Sturm trotzt sie, dieser Frau so fullend scharf,\nBewahrt ihre Flügel und ihr Lied und Herz im Takt.\nChor singt es laut und klar – die Geschichten derer, die geflohen,\nDauernd fortschreiten sie, im Glauben an eine Zukunft mal gewogen.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Gedicht über den Widerstand von migrantischen und geflüchteten Frauen.',
        'critics': ''
    },
    {
        'poem': 'A n den Medien sehen wir uns, Tag für Tag,\nB egegnen uns Vielfalt und Veränderung, kein Mangel, Juhu!\nC heteronorm war gestern, heut sind wir sichtbar und bunt,\nD enn Repräsentation zählt und ihr Wert ist uns kund.\n\nA n der Wandlung der Welt nehmen wir teil,\nB ringen Geschichten hervor und helfen am heil.\nC urious Blickwechsel, endlich gebrochen die Wand,\nD en Schleier gelüftet, für Sichtbarkeit Hand in Hand.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Lied über die Bedeutung von Sichtbarkeit und Repräsentation der LGBTQIA+ Gemeinschaft in den Medien.',
        'critics': ''
    },
    {
        'poem': "Armut heut' ist eine Plage, lasst uns kämpfen, keine Frage\nBildung stärkt, sie hält uns wach, sie macht uns geschickt beim Bruch der Schmach\nChancengleichheit - was wir brauchen, mehr als die Gesellschaft, die nur etwas versprach\nDenn der Kampf um Gerechtigkeit beginnt bei uns, zusammen sind wir stark\n\nAchtlos wie sie uns betrügen, mit falscher Hoffnung uns anlügen\nBefreien uns von ihren Ketten und festhalten, den Erfolg wir riechen\nCodes gibt es, dies zu knacken und zu befreien elender Seelen\nDas Ende der Armut zaubern, neuer Lebenstanz, wir sind die Rebellen",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Rap über die Dringlichkeit des Kampfes gegen Armut und soziale Ungleichheit.',
        'critics': ''
    },
    {
        'poem': 'Ach, mein Lieb, wie sehr ich schätze, \nBemühest dich um Cannabis zur Entlastung.\nCannabis bringt vielen Heilung und Hoffnung,\nDoch noch herrscht viel Unwissen und Bedrohung.\n\nAufklärung dein Schwert, Gerechtigkeit dein Schild,\nBekämpfst du diesen Kampf unermüdlich und wild.\nChamp der Freiheit, ich bin an deiner Seite,\nDein Widerstand, mein Stolz, und Liebe für die Weite.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Liebesbrief an einen Partner, der für die Legalisierung von medizinischem Cannabis kämpft.',
        'critics': ''
    },
    {
        'poem': "Aufstehen im Morgengrauen, für Gerechtigkeit wir schauen.\nBrüder, Schwestern, wir vereinen, Rassismus wird uns nicht mehr peinen.\nChancengleichheit wir erkämpfen, von der Freiheit wir nicht dämpfen.\nDurch Antirassismus wir leben, Liebe, Frieden wir erstreben.\n\nAufeinander wir nun bauen, auf Gerechtigkeit vertrauen.\nBürgerrechte wir erlangen, leis' verhallen Klagen, Bangen.\nC* steht für einst verbundene Ketten, die wir nemmehr hier zu stehen uns verletten.\nDen Kampf wir nie vergessen, das Leben jetzt genießen, einst Verletzten wir verbessern.",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Gedicht über den Kampf schwarzer Menschen für Gleichberechtigung und Antirassismus.',
        'critics': ''
    },
    {
        'poem': 'Ausbildung, Grundstein fürs Leben\nBildung lässt uns Flügel heben\nC, die Vielfalt die wir schätzen\nD, der Reichtum lässt uns schwätzen\n\nAnderer Kulturen wir behüten\nBaut Brücken, lässt uns glühen\nChancen, die wir alle feiern\nDankbar für das, was uns vereint.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Lied über die Bedeutung von Bildung und Wertschätzung kultureller Vielfalt.',
        'critics': ''
    },
    {
        'poem': "A uf dieser dunklen Straße steh'n\nB ekämpfen wir Geschlechterleid in jedem Seh'n\nC ontra Diskriminierung und Schmerz, wir geh'n\nD urch Feuer, Sturm, egal wie hoch die Wogen weh'n\n\nA lte Muster brechen, es ist Zeit zum Handeln aufgewacht\nB efrein uns von der Angst, die uns so machtlos macht\nC ome, bring zusammen jede Rasse, jedes Geschlecht \nD en Mut und Kraft in uns, unsere Waffe und das Recht",
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Rap über den Kampf gegen Geschlechtergewalt und Diskriminierung.',
        'critics': ''
    },
    {
        'poem': 'Ach, mein Liebling, der Du kämpfst für die Gerechten,\nBeeindruckst mich mit Deinem starken Worte,\nChampion der Arbeit, ich bewundere Dein Streben,\nDu bist mein Held, sorgst für die fairen Orte.\n\nEin Schimmer Hoffnung, gleich der ersten Sonnenstrahlen,\nFür jene, die in Arbeitssorgen sich plagen,\nGemeinsam steigern wir die soziale Schalen,\nHigh on Love, Dir, mein Kämpe will ich mich wagen.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Liebesbrief an einen Partner, der für Arbeitnehmerrechte und soziale Gerechtigkeit kämpft.',
        'critics': ''
    },
    {
        'poem': 'Allein Ihr Anblick erheitert mein Herz,\nBestaune Familien, divers und so scherz.\nCharmant verschieden, vereint in der Liebe,\nDie Vielfalt erleben, gar keine Trübe.\n\nAm Ende des Tages, die Liebe zählt,\nBunt und bescheiden, Familien gestählt.\nClan der Kulturen, gemeinsam sie wachsen,\nDivers und lebendig, stets darf es krachen.',
        'topic': '',
        'acrosticWord': '',
        'acrosticLine': ' ',
        'language': 'en',
        'poemSize': 8,
        'model': 'gpt4',
        'title': 'Ein Gedicht über die Schönheit von diversen und nicht-konventionellen Familienformen.',
        'critics': ''
    }]





}