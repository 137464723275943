import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from 'react-router-dom';
import { AuthModalContext, UserContext, UserDataContext } from '../App';
import { auth } from '../firebase/firebase';
import AuthModal from './AuthModal';
import { signOut } from 'firebase/auth';
import { useState } from 'react';
import { useContext } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Stack } from '@mui/material';
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';
import { ManageAccounts } from '@mui/icons-material';
import UserAccountModal from './AccountManagementModale';

const pages = ['Archives', 'About'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const { authModalOpen, setAuthModalOpen } = useContext(AuthModalContext)


    const { user, setUser } = useContext(UserContext);
    const { userData, setUserData } = useContext(UserDataContext);
    const [openUserAccountModal, setOpenUserAccountModal] = useState(false);

    const openAuthModal = () => setAuthModalOpen(true);
    const closeAuthModal = () => setAuthModalOpen(false);


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    auth.onAuthStateChanged((user) => {
        setUser(user);
    });

    const handleSignOut = () => {
        signOut(auth);
        setUser(null);
    };
    console.log('userData', userData)
    return (
        // Blanck background with black text for the header
        // black border for the bottom of the header
        <AppBar position="static"
            sx={{
                backgroundColor: 'transparent',
                color: 'black',
                borderBottom: '1px solid black',

            }}

        >
            <Container maxWidth="xl">


                <Toolbar disableGutters>
                    <img src="./android-chrome-384x384.png" sx={{ mr: 2 }}
                        style={{ maxWidth: 'auto', maxHeight: '50px', marginRight: '10px' }} />

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href={new URLSearchParams(window.location.search).get('gpuURL') === null ? "/" : "https://meme.koll.ai?gpuURL=" + new URLSearchParams(window.location.search).get('gpuURL')}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        PoetGPT
                    </Typography>


                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <MenuItem key={'home'} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center" color="inherit">Home</Typography>
                                </MenuItem>
                            </Link>

                            <Link to="/archives" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <MenuItem key={'archives'} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center" color="inherit">Archives</Typography>
                                </MenuItem>
                            </Link>

                            <Link to="/pricing" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <MenuItem key={'pricing'} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center" color="inherit">Pricing </Typography>
                                </MenuItem>
                            </Link>

                            <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <MenuItem key={'about'} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">FAQ & About</Typography>
                                </MenuItem>
                            </Link>

                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        PoetGPT
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                        justifyContent="center" gap={5}
                    >
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <Typography
                                key={'home2'}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'black', display: 'block' }}
                                variant="h6"
                            >
                                Home
                            </Typography>
                        </Link>
                        <Link to="/archives" style={{ textDecoration: 'none' }}>
                            <Typography

                                key={'archives2'}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'black', display: 'block' }}
                                variant="h6"
                            >
                                Archives
                            </Typography>
                        </Link>
                        <Link to="/pricing" style={{ textDecoration: 'none' }}>
                            <Typography
                                key={'pricing2'}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'black', display: 'block' }}
                                variant="h6"
                            >
                                Pricing
                            </Typography>
                        </Link>

                        <Link to="/about" style={{ textDecoration: 'none' }}>
                            <Typography

                                key={'about2'}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'black', display: 'block' }}
                                variant="h6"
                            >
                                FAQ & About
                            </Typography>
                        </Link>

                    </Box>
                    {/* Display user available credits */}
                    {user && (
                        <Box sx={{ marginRight: 2, display: { xs: 'none', md: 'flex' } }}>
                            <UserAccountModal open={openUserAccountModal}
                                handleClose={() => setOpenUserAccountModal(false)}
                            />
                            <Typography
                                key={'credits'}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'black', display: 'block' }}
                                variant="h6"
                            >
                                Credits: {userData.credits}
                            </Typography>
                        </Box>
                    )}



                    {user ? (
                        <Box>


                            <Avatar
                                alt={user.displayName}
                                onClick={handleOpenUserMenu}
                                sx={{ cursor: 'pointer' }}

                            >
                                {/* first letter of email */}
                                {user.email[0].toUpperCase()}
                            </Avatar>


                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <span> <strong> {user.email}</strong>'s Profile</span>
                                    </MenuItem>
                                </Link>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <span> Credits: {userData.credits}</span>
                                </MenuItem>

                                <Link to="/pricing" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <span> Purchase Credits</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/user_history" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        My History
                                    </MenuItem>
                                </Link>

                                <MenuItem onClick={() => { setOpenUserAccountModal(true) }}>
                                    Manage Account
                                </MenuItem>

                                <MenuItem onClick={handleSignOut}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    ) : (
                        <Box marginLeft="auto">
                            <Button color="inherit" onClick={openAuthModal}>
                                Sign Up / Login
                            </Button>
                        </Box>
                    )}
                </Toolbar>
            </Container>
            <AuthModal open={authModalOpen} onClose={closeAuthModal} />

        </ AppBar >
    );
}
export default ResponsiveAppBar;