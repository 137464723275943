// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBmVgV1cK1yF0O6gwtUHDbeJBkMUsxtXYo",
    authDomain: "gpt-poem.firebaseapp.com",
    projectId: "gpt-poem",
    storageBucket: "gpt-poem.appspot.com",
    messagingSenderId: "974905744886",
    appId: "1:974905744886:web:74c04b4a8dfa8becacfbd7",
    measurementId: "G-FGK8PNFJ3B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const functions = getFunctions(app)
if (process.env.REACT_APP_PROD === "false") {
    console.log('connecting to functions emulator')
    connectFunctionsEmulator(functions, "localhost", 5001);
}
const db = getFirestore(app);

export { app, auth, db, functions, analytics };