import { DE, ES, FR, IT, PT, US } from 'country-flag-icons/react/3x2'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckBox, Label, Lock } from '@mui/icons-material';
import { ArrowDownward, ArrowUpward, ThumbDown, ThumbUp } from '@mui/icons-material';
import { Box, FormControl, Card, Paper, CardContent, IconButton, Container, Grid, Select, MenuItem, TextField, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Slider, FormLabel, TextareaAutosize, FormControlLabel, Checkbox, Autocomplete, InputLabel } from '@mui/material';
import { useContext, useState, useEffect, useRef } from 'react';
import { notifyError, notifySuccess, UserContext } from '../App';
import { callGeneratePoem } from '../firebase/firebaseCalls';
import { randomTopicList } from './generatedExemples';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/firebase';

const acrosticWordByLang = {
    // remake the previous but in downcase
    en: ['will, never, give, you, up'],
    //wird dich niemals aufgeben
    de: ['wird, dich, niemals, aufgeben'],
    // non ti rinuncerà mai
    it: ['non, ti, rinuncerà, mai'],
    // nunca vai desistir de você
    pt: ['nunca, vai, desistir, de, você'],
    // parce que c'est notre projet
    fr: ['parce, que, c\'est, notre, projet'],
    ct: ['parce, que, c\'est, notre, projet'],
    qc: ['parce, que, c\'est, notre, projet'],
    // nunca te abandonaré
    es: ['nunca, te, abandonaré'],

}


//parce, que, c'est, notre, projet
// uppercase:
// PARCE QUE C'EST NOTRE PROJET




const CustomInputField = ({ inputRef, ...rest }) => {

    return (
        <TextareaAutosize
            {...rest}
            inputRef={inputRef}
            maxRows={10}
            minRows={1}
        />
    );
}





export function PanelGenParams({ generatedPoem, setGeneratedPoem, setPoemHistory, isAnimationEnabled, setIsAnimationEnabled, loading, setLoading, setDisplayMode }) {
    const { user, setUser } = useContext(UserContext);

    const MAX_POEM_SIZE = user === null ? 6 : 26;

    const styles = {
        "countryFlag": {
            maxWidth: '20px',
            maxHeight: '20px',
            marginRight: '5px'
        }
    }
    const URL_GENERATE_POEM = process.env.REACT_APP_BACKEND_URL + '/generatePoem';

    const [advancedParamsOpen, setAdvancedParamsOpen] = useState(false);

    const [generateButtonDisabled, setGenerateButtonDisabled] = useState(false);

    const getRandomTopic = (language) => {
        return randomTopicList[language][Math.floor(Math.random() * randomTopicList[language].length)];
    };

    const getRandomDisstrack = (language) => {
        // return a random topic whose title contain the words "disstrack" or "diss track"
        // if language is fr, qc or ct, return a random topic
        if (!(language === 'fr' || language === 'qc' || language === 'ct')) {
            return randomTopicList[language][Math.floor(Math.random() * randomTopicList[language].length)];
        }
        else {
            const filteredList = randomTopicList[language].filter(topic => topic.title.toLowerCase().includes('disstrack') || topic.title.toLowerCase().includes('diss track'));
            return filteredList[Math.floor(Math.random() * filteredList.length)];
        }
    };


    const [topicProgress, setTopicProgress] = useState(0);
    const topicProgressRef = useRef(topicProgress);
    const [generatedProgress, setGeneratedProgress] = useState(0);
    const generatedProgressRef = useRef(generatedProgress);
    const firstRandom = getRandomTopic(generatedPoem.language);
    const [randomTopic, setRandomTopic] = useState(firstRandom.title);
    const randomTopicRef = useRef(randomTopic);
    const [randomGenerated, setRandomGenerated] = useState(firstRandom.poem);
    const randomGeneratedRef = useRef(randomGenerated);

    const stopRef = useRef(false); // Add this ref to control when to stop

    const handleStop2 = () => {
        stopRef.current = true;
        setGenerateButtonDisabled(false);
    };

    const [isUserInteracted, setIsUserInteracted] = useState(false);

    const stopInterval = (interval) => {
        clearInterval(interval);
    };

    const handleUserInteraction = () => {
        setIsUserInteracted(true);
    };

    const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
    const [isGenerateButton, setIsGenerateButton] = useState(true);

    const [restartAnimation, setRestartAnimation] = useState(0);

    const [isFirstAnimation, setIsFirstAnimation] = useState(true);


    useEffect(() => {
        if (!isAnimationEnabled || isUserInteracted) return;

        topicProgressRef.current = 0;
        generatedProgressRef.current = 0;
        let randomExemple = null;
        if (isFirstAnimation) {
            randomExemple = getRandomDisstrack(generatedPoem.language);
            setIsFirstAnimation(false);
        }
        else {
            randomExemple = getRandomTopic(generatedPoem.language);
        }



        // setdisplayMode according to randomExemple
        if (randomExemple.acrosticWord.length > 0) {
            setDisplayMode('acrosticWord');
        } else if (randomExemple.acrosticLine.length > 0) {
            setDisplayMode('acrosticLine');
        } else {
            setDisplayMode('free');
        }



        const updateTopicInterval = setInterval(() => {
            if (topicProgressRef.current < randomExemple.title.length) {
                topicProgressRef.current = topicProgressRef.current + 1;
                setGeneratedPoem((prevGeneratedPoem) => {
                    return {
                        ...prevGeneratedPoem,
                        topic: randomExemple.title.substring(0, topicProgressRef.current),
                        title: randomExemple.title.substring(0, topicProgressRef.current)

                    }
                })
            }
            else if (generatedProgressRef.current < randomExemple.poem.length) {
                generatedProgressRef.current = generatedProgressRef.current + 1;
                setGeneratedPoem((prevGeneratedPoem) => {
                    return {
                        ...prevGeneratedPoem,
                        poem: randomExemple.poem.substring(0, generatedProgressRef.current)
                    }
                })



            } else {
                stopInterval(updateTopicInterval);
                setTimeout(() => {

                    const eraseTopicInterval = setInterval(() => {

                        if (generatedProgressRef.current > 0) {
                            generatedProgressRef.current = generatedProgressRef.current - 1;
                            setGeneratedPoem((prevGeneratedPoem) => {
                                return {
                                    ...prevGeneratedPoem,
                                    poem: randomExemple.poem.substring(0, generatedProgressRef.current),
                                }
                            });
                        }
                        else if (topicProgressRef.current > 0) {

                            topicProgressRef.current = topicProgressRef.current - 1;
                            setGeneratedPoem((prevGeneratedPoem) => {
                                return {
                                    ...prevGeneratedPoem,
                                    topic: randomExemple.title.substring(0, topicProgressRef.current),
                                    title: randomExemple.title.substring(0, generatedProgressRef.current)

                                }
                            });
                        } else {
                            stopInterval(eraseTopicInterval);
                            setTimeout(() => {
                                if (isUserInteracted) return;
                                setRestartAnimation(restartAnimation + 1);


                            }, 1000);
                        }
                    }, 5);
                }, 3000);
            }
        }, 20);

        return () => {
            stopInterval(updateTopicInterval);
        };
    }, [isUserInteracted, randomTopic, isAnimationEnabled, generatedPoem.language, restartAnimation]);
    // Call the handleStop function when clicking on the textfield or the generate button

    const handleStop = () => {
        stopRef.current = true;
        setGenerateButtonDisabled(false);
    };

    // Call the handleStop function when clicking on the textfield or the generate button


    const clearFields = () => {
        setIsFieldsDisabled(false);
    };

    const handleChangeTopic = (event) => {
        setErrorTopicField(event.target.value === '')
        setGeneratedPoem({ ...generatedPoem, topic: event.target.value })
    };

    const [errorTopicField, setErrorTopicField] = useState(false);


    const generatePoem = async () => {
        setIsUserInteracted(true);
        setIsAnimationEnabled(false);
        topicProgressRef.current = 0;

        setLoading(true);
        setGenerateButtonDisabled(true);
        if (generatedPoem.topic === '') {
            setErrorTopicField(true);
            setLoading(false);
            setGenerateButtonDisabled(false);
            return;

        }

        logEvent(analytics, 'generate_poem', {
            'language': generatedPoem.language,
            'poem_size': generatedPoem.poemSize,
            'acrostic_word': generatedPoem.acrosticWord,
            'acrostic_line': generatedPoem.acrosticLine,
            'topic': generatedPoem.topic,
            'language': generatedPoem.language,
        });

        await callGeneratePoem(generatedPoem.poemSize,
            generatedPoem.topic,
            generatedPoem.language,
            generatedPoem.acrosticWord,
            generatedPoem.acrosticLine,
            generatedPoem.model)

            .then((response) => {
                const newPoem = {
                    ...generatedPoem, poem: response.poem, title: response.title,
                    acrosticLine: response.acrosticLine, acrosticWord: response.acrosticWord
                };
                setGeneratedPoem(newPoem);
                setLoading(false);
                setGenerateButtonDisabled(false);
                notifySuccess('Poem generated successfully');
                setPoemHistory((prevHistory) => [newPoem, ...prevHistory]);
                setDisplayMode(generatedPoem.acrosticWord.length > 0 ? 'acrosticWord' : generatedPoem.acrosticLine.length > 0 ? 'acrosticLine' : 'free');
                const element_generated_poem = document.getElementById('generatedPoemBlock');
                element_generated_poem.scrollIntoView({ behavior: 'smooth', block: 'start' });

            })
            .catch((error) => {
                notifyError('Error: ' + error.message);
                setLoading(false);
                setGenerateButtonDisabled(false);
            });
    }

    return <Paper
        elevation={10}
        sx={{
            padding: 2,
            borderRadius: 2,
        }}
    >
        <Grid container spacing={2}>
            <Grid item sm={3} xs={12} order={{ sm: 2 }} >
                <Select value={generatedPoem.language} onChange={(e) => {
                    topicProgressRef.current = 0;
                    setGeneratedPoem({
                        ...generatedPoem, language: e.target.value
                    });

                }}
                    fullWidth
                    disabled={isFieldsDisabled}

                >
                    <MenuItem value="fr"> <FR style={styles.countryFlag} />French</MenuItem>

                    <MenuItem value="qc"> <img src="/CA-QC-Quebec-Flag.512.png" style={styles.countryFlag} /> French (Quebec)</MenuItem>
                    <MenuItem value="ct"> <img src="/Flag_of_Picardie.svg" style={styles.countryFlag} /> French (Picard/Ch'ti)</MenuItem>

                    <MenuItem value="en"> <US style={styles.countryFlag} /> English</MenuItem>
                    <MenuItem value="es"> <ES style={styles.countryFlag} />Spanish</MenuItem>
                    <MenuItem value="de"> <DE style={styles.countryFlag} />German</MenuItem>
                    <MenuItem value="it"> <IT style={styles.countryFlag} />Italian</MenuItem>
                    <MenuItem value="pt"> <PT style={styles.countryFlag} />Portuguese</MenuItem>

                </Select>
                <Box marginTop={2} display="flex" justifyContent="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAnimationEnabled}
                                onChange={(e) => {
                                    setIsUserInteracted(!e.target.checked);
                                    setIsAnimationEnabled(e.target.checked)
                                }}
                                disabled={isFieldsDisabled}

                            />
                        }
                        label="Random prompt animation"
                    />
                </Box>
            </Grid>
            <Grid item sm={9} xs={12} order={{ sm: 1 }}>
                <FormControl fullWidth>
                    <TextField
                        label="Prompt"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                        value={generatedPoem.topic}
                        onChange={handleChangeTopic}
                        // sx={{ marginBottom: 2 }}
                        error={errorTopicField}
                        helperText={'Description of what you want to generate. ' + (generatedPoem.model === 'gpt4' ? '(unlimited)' : user ? `(${generatedPoem.topic.length}/300)` : `(${generatedPoem.topic.length}/100)`)}
                        id="poemTopicTitle"
                        inputProps={{

                            maxLength: generatedPoem.model === 'gpt4' ? 10000 : user ? 300 : 100,
                            inputComponent: CustomInputField,
                            style: { fontSize: 30, lineHeight: 1.2, fontFamily: 'Roboto Mono' },
                            minRows: 1,
                            maxRows: 3,
                        }}

                        onFocus={() => {
                            setIsAnimationEnabled(false);
                            setGenerateButtonDisabled(false);
                            topicProgressRef.current = 0;
                        }
                        }
                        disabled={isFieldsDisabled}


                    />
                </FormControl>
                <Box marginTop={2} display="flex" justifyContent="center">
                </Box>

            </Grid>

            {/* 
            {true &&
                <Grid item xs={12}>

                    <Accordion expanded={advancedParamsOpen} onChange={() => setAdvancedParamsOpen(!advancedParamsOpen)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="advanced-params-content"
                            id="advanced-params-header"
                        >
                            <Typography>Poem Length, Acrostic, AI Model...</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}> */}

            {/* Generation mode select, one of Free, Acrostic */}
            {/* If selected item is acrostic, there should be a textfield for the acrosticLine */}
            <Grid item sm={4} xs={4} order={{ sm: 3 }}>
                <FormControl fullWidth>
                    <InputLabel id="generation-mode-select-label">Generation Mode</InputLabel>
                    <Select
                        label="Generation Mode"
                        value={generatedPoem.generationMode}
                        onChange={(e) => {
                            setGeneratedPoem({ ...generatedPoem, generationMode: e.target.value, acrosticLine: '', acrosticWord: '' })
                            setIsAnimationEnabled(false);
                            // use default value when picked
                        }}
                        disabled={isFieldsDisabled}
                    >
                        <MenuItem value="free">Free</MenuItem>
                        <MenuItem value="acrosticLine">Acrostic Line</MenuItem>
                        <MenuItem value="acrosticWord">Acrostic Word</MenuItem>

                    </Select>
                </FormControl>
            </Grid>
            {/* If selected item is acrostic, there should be a textfield for the acrosticLine */}

            {/* select poem size from short, medium, long */}
            <Grid item sm={4} xs={4} order={{ sm: 4 }}>
                <FormControl fullWidth>
                    <InputLabel id="poem-size-select-label">Output Size</InputLabel>
                    <Select

                        label="Output Size"
                        value={generatedPoem.poemSize}
                        onChange={(e) => {
                            setIsAnimationEnabled(false);
                            setGeneratedPoem({ ...generatedPoem, poemSize: e.target.value })
                        }}
                        disabled={isFieldsDisabled}
                    >
                        <MenuItem value={'small'} >Small {generatedPoem.model === 'gpt4' && <span> &nbsp; &nbsp; &nbsp; (1 GPT-4 Credit)</span>} </MenuItem>
                        <MenuItem value={'medium'} disabled={!user}>Medium {generatedPoem.model === 'gpt4' && '(2 GPT-4 Credits)'} {!user && <span> &nbsp; Need to be logged in </span>}
                            {!user && <div><Lock style={{ marginLeft: 8 }} /> </div>}</MenuItem>
                        <MenuItem value={'large'} disabled={!user}>Large {generatedPoem.model === 'gpt4' && <span>  &nbsp; &nbsp; &nbsp; (3 GPT-4 Credits)</span>} {!user && <span> &nbsp; Need to be logged in </span>}
                            {!user && <div><Lock style={{ marginLeft: 8 }} /> </div>}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {/* select AI model from chatgpt, gpt4 */}
            <Grid item sm={4} xs={4} order={{ sm: 5 }}>
                <FormControl fullWidth>
                    <InputLabel id="ai-model-select-label">AI Model</InputLabel>
                    <Select
                        label="AI Model"
                        value={generatedPoem.model}
                        onChange={(e) => {
                            setGeneratedPoem({ ...generatedPoem, model: e.target.value })
                            setIsAnimationEnabled(false);
                        }}
                        fullWidth
                        disabled={isFieldsDisabled}

                    >
                        <MenuItem value="chatgpt">ChatGPT</MenuItem>
                        <MenuItem value="gpt4" disabled={!user}>
                            GPT-4 {!user && <span> &nbsp; Need to be logged in </span>}
                            {!user && <div><Lock style={{ marginLeft: 8 }} /> </div>}
                        </MenuItem>

                    </Select>
                </FormControl>
            </Grid>
            {generatedPoem.generationMode === 'acrosticLine' &&

                <Grid item sm={12} xs={12} order={{ sm: 7 }}>
                    <Typography variant="caption" display="block" gutterBottom sx={{ marginBottom: 2 }}>
                        The first letter of each line will be the next letter in the following sequence
                    </Typography>
                    <FormControl fullWidth>

                        <Autocomplete
                            freeSolo
                            options={['LOVE', 'A to Z']}
                            inputValue={generatedPoem.acrosticLine}
                            onInputChange={(e, newValue) => {
                                setGeneratedPoem({ ...generatedPoem, acrosticLine: newValue })
                                setIsAnimationEnabled(false);

                            }
                            }
                            renderInput={(params) =>
                                <TextField {...params} label="Acrostic Line"
                                    helperText={'Sequence for each line start. Works better with GPT-4.'}
                                />
                            }
                            disabled={isFieldsDisabled}

                        />
                    </FormControl>
                </Grid>
            }

            {generatedPoem.generationMode === 'acrosticWord' &&
                <Grid item sm={12} xs={12} order={{ sm: 8 }}>
                    <Typography variant="caption" display="block" gutterBottom sx={{ marginBottom: 2 }}>
                        The first letter of each word will be the next letter in the following sequence
                    </Typography>
                    <FormControl fullWidth>
                        <Autocomplete
                            freeSolo
                            options={acrosticWordByLang[generatedPoem.language]}
                            inputValue={generatedPoem.acrosticWord}
                            onInputChange={(e, newValue) => {
                                setGeneratedPoem({ ...generatedPoem, acrosticWord: newValue })
                                setIsAnimationEnabled(false);
                            }}
                            renderInput={(params) =>
                                <TextField {...params} label="Acrostic Word"
                                    helperText={'Sequence for each word start. Works better with GPT-4.'}
                                />
                            }
                            disabled={isFieldsDisabled}
                        />
                    </FormControl>



                </Grid>
            }

            <br />
            <Grid item xs={12} order={{ sm: 9 }}>

                <Box display="flex" justifyContent="center" marginBottom={1} marginTop={0}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={generatePoem}
                        disabled={generateButtonDisabled}
                    >
                        Generate!
                    </Button>
                </Box>
            </Grid>

        </Grid>


    </Paper>
}

